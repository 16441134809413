import React, { useMemo } from 'react';
import * as THREE from 'three';

export const CustomTriangle = ({ vertices, position }) => {
  const f32array = Float32Array.from(vertices.flatMap((item, index) => vertices));

  return (
    <mesh position={position}>
      <bufferGeometry attach='geometry'>
        <bufferAttribute attachObject={['attributes', 'position']} args={[f32array, 3]} />
      </bufferGeometry>
      <meshBasicMaterial
        attach='material'
        color='#5243aa'
        wireframe={false}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export const CustomTube = ({ points }) => {
  const curveARR = points.map(p => new THREE.Vector3(...p));
  const curve = new THREE.CatmullRomCurve3(curveARR);

  return (
    <mesh>
      <tubeGeometry args={[curve, 1, 1, 20, false]} />
      <meshStandardMaterial color={0xd2452b} side={THREE.DoubleSide} />
    </mesh>
  );
};

export const CustomShape = () => {
  const vertices = new Float32Array([
    -1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0, 1.0, 1.0,

    1.0, 1.0, 1.0, -1.0, 1.0, 1.0, -1.0, -1.0, 1.0
  ]);
  return (
    <mesh>
      <bufferGeometry attach='geometry'>
        <bufferAttribute attach='attributes-position' array={vertices} itemSize={3} count={6} />
      </bufferGeometry>
      <meshBasicMaterial attach='material' color='hotpink' />
    </mesh>
  );
};
