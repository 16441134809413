export const LuckyOrangeWrapper = ({ children }) => {
  return (
    <div
      async
      defer
      src='https://tools.luckyorange.com/core/lo.js?site-id=a19f12b6'
      style={{ height: '100%' }}
    >
      {children}
    </div>
  );
};
