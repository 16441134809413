import { DoubleSide } from 'three';

export const Floor = ({ property }) => {
  /* const [ref] = usePlane(() => ({ mass: 0, ...props }), useRef()); */
  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} /* ref={ref} */ receiveShadow>
      <planeGeometry args={property} />
      <meshBasicMaterial
        /* color='#efa21e' */ color='#F1F2F4'
        side={DoubleSide}
        depthWrite={false}
        flatShading={false}
      />
    </mesh>
  );
};
