/* const ARROW_BODY = new THREE.CylinderGeometry(1, 1, 1, 12)
  .rotateX(Math.PI / 2)
  .translate(0, 0, 0.5); */

const ArrowBody = ({ color, scale }) => {
  return (
    <mesh
      scale={scale} /* rotateY={-Math.PI / 2} */
      /* rotation={[Math.PI / 2, 0, 0]} */
      /* rotateZ={Math.PI / 2} */
    >
      <cylinderGeometry
        args={[0.25, 0.25, 20, 12]}
        /* rotateY={-Math.PI / 2} */
        translate={[0, 0, 0.5]}
        rotateX={Math.PI / 2}
      />
      <meshBasicMaterial color={color} />
    </mesh>
  );
};

/* const ARROW_HEAD = new THREE.ConeGeometry(1, 1, 12).rotateX(Math.PI / 2).translate(0, 0, -0.5); */

const ArrowHead = ({ color, scale, position }) => {
  return (
    <mesh
      scale={scale}
      /* rotation={[Math.PI / 2, 0, 0]} */ /* translate={[0, 0, -0.5]} translateOnAxis={} */
      position={position}
      /* translateOnAxis={[0, 0, -0.5]} */
    >
      <coneGeometry
        /* scale={scale} */
        args={[1, 4, 12]}
        translate={[0, 0, -0.5]} /* translate={[0, 0, -0.5]} */
        rotateX={Math.PI / 2}
      />
      <meshBasicMaterial color={color} />
    </mesh>
  );
};

export const CustomArrow = ({ from, to, thickness, color }) => {
  const [fx, fy, fz] = from.length > 0 ? from : [0, 0, 0];
  const [ix, iy, iz] = to.length > 0 ? to : [0, 0, 0];
  /*   const fx = from[0];
  const fy = from[2];
  const fz = from[1];
  const ix = to[0];
  const iy = to[2];
  const iz = to[1]; */
  /* var material = new THREE.MeshLambertMaterial({ color: color });
   */
  const length = Math.sqrt((ix - fx) ** 2 + (iy - fy) ** 2 + (iz - fz) ** 2);
  console.log(from, to, length, 'length arrow');

  /*  var body = new THREE.Mesh(ARROW_BODY, material); */
  /* body.scale.set(thickness, thickness, length - 10 * thickness); */

  /* var head = new THREE.Mesh(ARROW_HEAD, material); */
  /* head.position.set(0, 0, length);
  head.scale.set(3 * thickness, 3 * thickness, 10 * thickness); */

  /* var arrow = new THREE.Group();
  arrow.position.set(ix, iy, iz);
  arrow.lookAt(fx, fy, fz);
  arrow.add(body, head); */

  return (
    <group position={[ix, iy, iz]} lookAt={[fx, fy, fz]}>
      <ArrowHead
        position={[0, 0, length]}
        scale={[3 * thickness, 3 * thickness, 10 * thickness]}
        color={color}
      />
      <ArrowBody scale={[thickness, thickness, length - 10 * thickness]} color={color} />
    </group>
  );
};
