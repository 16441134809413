import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import { ThreeDPage } from './pages/threed-page';
import { DownloadPage } from './pages/download-page';
import { ImagePage } from './pages/image-page';
import { Rollbar } from './rollbar';
import { MatrixPage } from './pages/matrix-page';

//force main
function App() {
  const routePath = {
    threed: '/results/:cubesVersion/:runnerId/:index',
    download: '/download/cubes/:runnerId/:index',
    image: '/results/:cubesVersion/image/:runnerId/:index',
    matrix: '/results/getMatrix/:runnerId'
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={routePath.threed}
          element={
            <Rollbar>
              <ThreeDPage />
            </Rollbar>
          }
        />
        <Route path={routePath.download} element={<DownloadPage />} />
        <Route
          path={routePath.image}
          element={
            <Rollbar>
              <ImagePage />
            </Rollbar>
          }
        />
        <Route path={routePath.matrix} element={<MatrixPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
