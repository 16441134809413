import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

export const Chart = ({ facility }) => {
  const data = [
    { id: 0, value: 10, label: 'series A' },
    { id: 1, value: 15, label: 'series B' },
    { id: 2, value: 20, label: 'series C' },
    { id: 3, value: 5, label: 'series D' }
  ];

  return (
    <PieChart
      series={[
        {
          arcLabel: item => `${item.value}%`,
          arcLabelMinAngle: 45,
          data: facility
        }
      ]}
      /* slotProps={{ legend: { hidden: true } }} */
      margin={{ left: 10, right: 10 }}
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'top', horizontal: 'middle' },
          padding: 0,
          hidden: true
        }
      }}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontSize: '12px',
          fontWeight: 'bold'
        },
        zIndex: 1500
      }}
      width={400}
      height={200}
    />
  );
};
