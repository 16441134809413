import { useEffect, useState } from 'react';
import { api } from '../api/api';
import axios from 'axios';
import { ThreeDContent } from '../components/3d-content';
import { LoaderMUI } from '../global/loader/loader-mui';

import '../components/page-wrapper.css';
import { convertCubes } from '../utils/convert-cubes';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRollbar } from '@rollbar/react';
import { ErrorMessage } from '../global/error/error';
import { Matrix } from '../components/matrix/matrix';
import { MatrixThreeD } from '../components/matrix/matrix-3d';
import { LuckyOrangeWrapper } from '../components/luckyorange_wrapper';

export const ThreeDPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const rollbar = useRollbar();

  const [layoutData, setLayoutData] = useState(null);
  const [matrix, setMatrix] = useState(null);
  const [user, setUser] = useState(null);
  const [cubesOrig, setCubesOrig] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  /* const runnerIdParams = searchParams.get('runnerId') || '';
  const indexParams = searchParams.get('index'); */

  const { cubesVersion, runnerId, index } = useParams();

  const fetchData = async controller => {
    setIsLoading(true);
    setError(false);
    setLayoutData(null);

    try {
      const response = await api.get(
        `results/${cubesVersion}/${runnerId}/${index}`
        /* {
            headers: { Authorization: `Token ${token}` },
            signal: controller ? controller.signal : null
          } */
      );

      /* console.log(response); */
      const cubesIndexOrig = [...response.data.cubes];
      const { layout, cubes, flows, facility, excel, kpis } = convertCubes(response.data, index);
      /* console.log(layout, cubes); */
      setMatrix(response.data.matrix);
      setCubesOrig(cubesIndexOrig);
      setLayoutData({ threeDCubes: layout, cubes, flows, facility, excel, kpis });
      setUser({ id: response.data.project.userId, email: response.data.project.email });
      setIsLoading(false);
      setError(false);
    } catch (error) {
      rollbar.error('Error fetching data', error);
      if (axios.isCancel(error)) {
        console.log('axios cancel');
      } else {
        setError(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, []);

  /*  const cells = countCellsArea(layouts);
  console.log(cells); */

  const matrixData = matrix
    ? [
        {
          matrix: matrix,
          sortBy: ''
        }
      ]
    : [];

  return (
    /*  <LuckyOrangeWrapper> */
    <div className='page-wrapper'>
      {isLoading && <LoaderMUI />}
      {!isLoading && error && <ErrorMessage />}
      {!isLoading && !error && layoutData && <ThreeDContent layoutData={layoutData} user={user} />}
      {/* <div style={{ display: 'flex', height: '100%' }}>
        {!isLoading && !error && layoutData && <ThreeDContent layoutData={layoutData} />}
        {matrix && <MatrixThreeD data={matrixData} cubes={cubesOrig} />}
      </div> */}

      {/* <ThreeDContent
        layoutData={{
          threeDCubes: {
            cubes: dataJSON.cubes,
            project: { 'x-dim': 100, 'y-dim': 100 },
            flows: []
          }
        }}
      /> */}
    </div>
    /*  </LuckyOrangeWrapper> */
  );
};
