export const color_set_1 = [
  '#5E6278',
  '#0073A9',
  '#70AF99',
  '#E7AB52',
  '#C67E7E',
  '#694E4E',
  '#9595CC',
  '#8BC1F7',
  '#F9E0A2',
  '#D5B4B4',
  '#9A3B3B',
  '#946EA8',
  '#468971',
  '#2f4b7c',
  '#a05195',
  '#ff7c43',
  '#ffa600',
  '#BDE2B9',
  '#A2D9D9',
  '#B2B0EA',
  '#B8BBBE',
  '#C6AB52',
  '#C67E52',
  '#467E7E'
];
