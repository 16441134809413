import { useState } from 'react';
import MatrixCheckbox from './matrix-checkbox';

export const MatrixSidebar = ({ flowList, selectedChildren, setSelectedChildren }) => {
  return (
    <div className='matrix-sidebar'>
      <MatrixCheckbox
        flowList={flowList}
        selectedChildren={selectedChildren}
        setSelectedChildren={setSelectedChildren}
      />
    </div>
  );
};
