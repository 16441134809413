import { Line, Tetrahedron, Text, useCursor } from '@react-three/drei';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { ArrowHelperFun } from './arrow-helper';
import { CustomArrow } from './custom-arrow';
import { CustomLine } from './line';
import { CustomTube } from './triangle';
import { calculateTextSize } from '../../utils/calculate-size';

export const FlowLine = ({
  start,
  end,
  flowIntensity,
  intensityValue,
  selected,
  maxLayoutSize
}) => {
  const shape = new THREE.Shape();

  const x = 0;
  const y = 0;

  shape.moveTo(x - 2, y - 2);
  shape.lineTo(x + 2, y - 2);
  shape.lineTo(x, y + 2);

  const TriangleGeometry = new THREE.ShapeGeometry(shape);

  const args = [
    -1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0, 1.0, 1.0,

    1.0, 1.0, 1.0, -1.0, 1.0, 1.0, -1.0, -1.0, 1.0
  ];

  const [hovered, setHovered] = useState(false);
  useCursor(hovered);

  // Calculate the ratio of intensity relative to the range
  const ratio = (intensityValue - flowIntensity.min) / (flowIntensity.max - flowIntensity.min);

  // Calculate the width based on the ratio
  const minWidth = 8;
  const maxWidth = 32;
  const width = minWidth + ratio * (maxWidth - minWidth);

  const nameSize = calculateTextSize(maxLayoutSize);

  /*   const ref = useRef();
  useLayoutEffect(() => {
    ref.current.geometry.setFromPoints([start, end].map(point => new THREE.Vector3(...point)));
  }, [start, end]); */
  /*    <lineSegments>
   <bufferGeometry
          <bufferAttribute attach="attributes-position" args={[positions, 3]} />
          <bufferAttribute attach="attributes-colors" args={[colors, 3]} />
        </bufferGeometry>
        <lineBasicMaterial color="black" vertexColors />
      </lineSegments> */

  /* const vertices = [start, end].map(point => new THREE.Vector3(...point)); */

  return selected ? (
    <>
      {/* <lineSegments>
        <bufferGeometry>
          <bufferAttribute attach='attributes-position' args={[...vertices, 3]} />
          <bufferAttribute attach='attributes-colors' args={[colors, 3]} />
        </bufferGeometry>
        <lineBasicMaterial color='black' linecap='butt' vertexColors />
      </lineSegments> */}
      {/* <CustomLine points={[start, end]} /> */}

      <Line
        points={[start, end]}
        color={hovered ? '#fff' : '#6A819E'}
        lineWidth={width} // In pixels (default)
        opacity={0.5}
        transparent={true}
        linecap={'butt'}
        onPointerOver={event => {
          event.stopPropagation();
          setHovered(true);
        }}
        onPointerOut={event => setHovered(false)}
        segments
        clipping={true}
      ></Line>
      {/*       <shapeGeometry args={args}></shapeGeometry> */}
      {/* {hovered && (
        <Text
          position={[start[0], 1, start[2] + 4]}
          rotation={[-Math.PI / 2, 0, 0]}
          text={intensityValue}
          fontSize={2}
          fontWeight={600}
          color={'#000'}
          depthWrite={false}
          flatShading={false}
        />
      )} */}
      {hovered && (
        <Text
          /* position={[end[0], 1, end[2] - nameSize * 2]} */
          position={[(end[0] + start[0]) / 2, 1, (end[2] + start[2]) / 2]}
          rotation={[-Math.PI / 2, 0, 0]}
          text={intensityValue}
          fontSize={nameSize * 1.5}
          color={'#000'}
          fontWeight={600}
          depthWrite={false}
          flatShading={false}
        />
      )}
      {/* <CustomTube
        points={[
          [start[0], start[1], start[2]],
          [end[0], end[1], end[2]]
        ]}
      /> */}
      <ArrowHelperFun
        from={start}
        to={end}
        flowIntensity={flowIntensity}
        headLength={maxLayoutSize > 150 ? 4 : 2}
        headWidth={maxLayoutSize > 150 ? 2 : 1}
      />
      {/*  <CustomArrow from={end} to={start} color={0x6a819e} thickness={0.25} /> */}
      {/*       <line ref={ref}>
        <bufferGeometry />
        <lineBasicMaterial
          linecap='butt'
          color={'red'}
          linewidth={5}
        />
      </line> */}
    </>
  ) : null;
};
