import { useEffect, useState } from 'react';
import { api } from '../api/api';
import axios from 'axios';
import { cubesData, dataJSON } from '../components/data';
import { ThreeDContent } from '../components/3d-content';
import { LoaderMUI } from '../global/loader/loader-mui';

import '../components/page-wrapper.css';
import { convertCubes } from '../utils/convert-cubes';

export const DownloadPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [layoutData, setLayoutData] = useState(null);

  const fetchData = async controller => {
    setIsLoading(true);
    setError(false);
    setLayoutData(null);

    try {
      const response = await api.get(
        `results/cubes/XGb6aqLIFVmUoweKL5yN/0`
        /* {
            headers: { Authorization: `Token ${token}` },
            signal: controller ? controller.signal : null
          } */
      );

      console.log(response);
      const { layout, cubes, flows } = convertCubes(response.data);
      console.log(layout, cubes);
      setLayoutData({ threeDCubes: layout, cubes, flows });
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('axios cancel');
      } else {
        setError(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, []);

  /*  const cells = countCellsArea(layouts);
  console.log(cells); */

  return (
    <div className='page-wrapper'>
      {isLoading && <LoaderMUI />}
      {!isLoading && !error && layoutData && <ThreeDContent layoutData={layoutData} />}
      <ThreeDContent
        layoutData={{
          threeDCubes: {
            cubes: dataJSON.cubes,
            project: { 'x-dim': 100, 'y-dim': 100 },
            flows: []
          }
        }}
      />
    </div>
  );
};
