import { Button, Drawer, Pagination, Tooltip } from '@mui/material';
import { Fragment, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { MatrixSidebar } from './matrix-sidebar';
import { ReactComponent as IconChevronLeft } from '../../assets/chevron-left.svg';

import './matrix.css';
import { color_set_1 } from '../../utils/colors';
import { Matrix } from './matrix';
const drawerWidth = 420;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen
  }),
  width: open ? 'calc(100% - 420px)' : '100%',
  /* width: '100%', */
  marginRight: 0,
  ...(!open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
    /* marginRight: -drawerWidth */
    /* width: '100%' */
  }),
  position: 'relative'
}));

export const MatrixWrapper = ({ matrixData }) => {
  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const index = (page - 1) * 2;
  /*  const data = matrixData.slice(index, index + 2); */
  const data = matrixData.candidates.slice(page - 1, page);
  const width = data[0].matrix.length * 16;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleDrawerOpen = () => {
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    setSidebarOpen(false);
  };

  const [selectedFlow, setSelectedFlow] = useState([]);

  return (
    <div className='matrix-page'>
      <Main open={sidebarOpen} className='main-content'>
        <Matrix data={data} index={index} page={page} selectedFlow={selectedFlow} />
        <Pagination
          /* count={Math.ceil(matrixData.length / 2)} */
          count={matrixData.candidates.length}
          page={page}
          onChange={handlePageChange}
          boundaryCount={1}
          showFirstButton={true}
          showLastButton={true}
          color='primary'
          shape='rounded'
          variant='outlined'
          sx={{
            '.MuiPaginationItem-root': {
              color: '#5e6278'
              /* fontSize: '13px',
            fontWeight: 600 */
            },
            '.Mui-selected': {
              /*  background: '#fff !important', */
              /* color: '#fcfcfd !important' */
            }
          }}
        />
      </Main>
      <Button
        color='inherit'
        aria-label='open drawer'
        onClick={sidebarOpen ? handleDrawerClose : handleDrawerOpen}
        edge='start'
        className='sidebar-toggle'
        sx={{
          right: sidebarOpen ? `${drawerWidth}px` : '0px',
          transition: sidebarOpen
            ? 'right 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
            : 'right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          svg: {
            transform: sidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          }
        }}
      >
        <IconChevronLeft />
      </Button>
      <Drawer
        sx={{
          width: sidebarOpen ? drawerWidth : 0,
          flexShrink: 0,
          /* position: 'absolute',
            top: 0,
            right: 0, */
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            overflow: 'hidden',
            borderLeft: 'none'
          }
        }}
        variant='persistent'
        anchor='right'
        open={sidebarOpen}
      >
        {data[0].connections && data[0].connections.length > 0 ? (
          <MatrixSidebar
            flowList={data[0].connections}
            selectedChildren={selectedFlow}
            setSelectedChildren={setSelectedFlow}
          />
        ) : (
          'No flow'
        )}
      </Drawer>
    </div>
  );
};
