export const calculateTextSize = layoutSize => {
  // Calculate the exponent of 2 for the layout size
  const exponent = Math.log2(layoutSize / 50);

  // Calculate the text size based on the exponent
  let textSize = Math.pow(2, exponent) * 0.5;

  // Clamp the text size to the range of 0.5 to 2
  textSize = Math.min(2, Math.max(0.5, textSize));

  return textSize;
};
