import React, { Component, useEffect, useLayoutEffect, useRef } from 'react';
import * as THREE from 'three';

export const ArrowHelperFun = ({ from, to, color = 0x4f5c72, headLength = 4, headWidth = 2 }) => {
  const arrowRef = useRef();
  const fromVector = new THREE.Vector3(...from);
  const toVector = new THREE.Vector3(...to);

  var fromV = new THREE.Vector3(...from);
  var toV = new THREE.Vector3(...to);

  var direction = toV.clone().sub(fromV);
  var length = direction.length();

  /*   useLayoutEffect(() => {
    console.log(arrowRef, arrowRef?.current, arrowRef?.current?.line, 'arrow ref');
    if (arrowRef) {
      arrowRef.current.line.material.linewidth = 10;
      arrowRef.current.line.material.linecap = 'butt';
    }
  });
 */
  // var arrowHelper = new THREE.ArrowHelper(direction.normalize(), from, length, 0xff0000 );

  return (
    <arrowHelper
      ref={arrowRef}
      args={[direction, fromV, length, color, headLength, headWidth]}
      linewidth={10}
    />
  );
};
