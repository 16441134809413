import { useMemo } from 'react';
import * as THREE from 'three';

export const CustomLine = ({ points }) => {
  const vertices = useMemo(() => points.map(point => new THREE.Vector3(...point)), [points]);
  return (
    <line>
      <geometry vertices={vertices} />
      <lineBasicMaterial color='red' />
    </line>
  );
};
