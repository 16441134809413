import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Collapse, FormGroup, IconButton, Typography, styled } from '@mui/material';
import { ChildrenList } from './children-list';
import { useState } from 'react';
import { ReactComponent as IconChevronDown } from '../../../assets/chevron-down.svg';
import { useEffect } from 'react';

const ExpandMore = styled(props => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default function ParentCheckbox({
  parent,
  search,
  handleDepartmentToggle,
  handleCubeToggle,
  selectedCubesNew,
  expandAll,
  isFlow
}) {
  const idName = isFlow ? 'connection_id' : 'id';

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(expandAll);
  }, [expandAll]);

  const parentCubes = search
    ? parent.children
        .filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
        .map(cube => cube[idName])
    : parent.children.map(cube => cube[idName]);

  return (
    <div className='tree-row'>
      <div className='parent-row'>
        {/* {isFlow && (
          <Typography className='from' color='text.secondary' fontSize='0.75rem' >
            from
          </Typography>
        )} */}
        <FormControlLabel
          label={parent.name}
          control={
            <Checkbox
              checked={parentCubes.every(cubeID => selectedCubesNew.includes(cubeID))}
              onChange={e => handleDepartmentToggle(e, parent)}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                padding: '5px',
                svg: {
                  width: '18px',
                  height: '18px'
                  // fill: '#fff5e6'
                },
                color: '#d0d5dd',
                '&.Mui-checked': {
                  color: '#efa21e'
                }
              }}
            />
          }
          sx={{
            /* width: '100%', */
            /*  '& .MuiFormControlLabel-label': { width: '100%' }, */
            '&.MuiFormControlLabel-root': { mr: 0, ml: 0 },
            //borderBottom: '1px solid #F2F4F7',
            padding: '8px 0'
          }}
        />
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
          sx={{
            color: 'secondary.light',
            '&:hover': {
              background: '#fff5e6',
              color: 'primary.main'
            },
            padding: '4px',
            borderRadius: '4px'
          }}
        >
          <IconChevronDown width='18px' height='18px' />
        </ExpandMore>
      </div>

      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', ml: isFlow ? 1 : 3, mr: isFlow ? 2 : 0 }}
        >
          <ChildrenList
            childrenList={parent.children}
            search={search}
            handleCubeToggle={handleCubeToggle}
            selectedCubesNew={selectedCubesNew}
            isFlow={isFlow}
          />
        </Box>
      </Collapse>
    </div>
  );
}
