import { Button, Tooltip } from '@mui/material';
import { Fragment, useState } from 'react';

import './matrix.css';
import { color_set_1 } from '../../utils/colors';
import { facilityColors } from '../../utils/convert-cubes';

export const MatrixThreeD = ({ data, cubes }) => {
  const [src, setSrc] = useState(0);
  const reloadHandler = () => {
    setSrc(Math.random());
  };

  return (
    <div className='matrix-wrapper'>
      {data.map((matrix, i) => (
        <Fragment key={'matrix' + i}>
          <div className='matrix-item'>
            {/*  <div className='matrix-title'>Matrix {page - 1}</div>
            <div className='kpi'>
              <div>
                density: <b>{matrix.density}</b>
              </div>
              <div>
                sort by: <b>{matrix.sortBy}</b>
              </div>
              <div>
                perimeter: <b>{matrix.perimeter}</b>
              </div>
            </div>
            <div className='kpi second'>
              <div>
                connDistance: <b>{matrix.connDistance && matrix.connDistance.toFixed(5)}</b>
              </div>
              <div>
                connDistancePer:{' '}
                <b>{matrix.connDistancePer && matrix.connDistancePer.toFixed(5)}</b>
              </div>
            </div> */}

            <div className='column-number-wrapper'>
              {matrix.matrix[0].map((row, i) => (
                <div className='column-number' key={'column' + i}>
                  {i}
                </div>
              ))}
            </div>

            <div className='matrix'>
              {matrix.matrix.map((row, y) => (
                <div className='matrix-row' key={'row' + y}>
                  <div className='row-number'>{y}</div>
                  {row.map((cell, x) => (
                    <Tooltip
                      title={cell === -1 ? '' : `${x}, ${y} ${cubes[cell].name}`}
                      placement='right'
                      key={'tooltip' + y + x}
                      arrow
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10]
                              }
                            }
                          ]
                        }
                      }}
                    >
                      <div
                        className='matrix-cell'
                        key={'cell' + y + x}
                        style={{
                          border: `1px solid ${
                            cell === -1
                              ? '#d0d5dd'
                              : cell === -2
                              ? '#d0d5dd'
                              : /* color_set_1[cell].concat('90') */ facilityColors[
                                  cubes[cell].facility_type
                                ]
                          }`,
                          background:
                            cell === -1
                              ? 'none'
                              : cell === -2
                              ? '#565554'
                              : /* color_set_1[cell] */ /* !!selectedFlow.find(
                                  f => f.cube1Index === cell || f.cube2Index === cell
                                ) */ false
                              ? '#fff'
                              : /* color_set_1[cell].concat('90') */ facilityColors[
                                  cubes[cell].facility_type
                                ] /* .concat('90') */
                        }}
                      >
                        <span style={{ color: '#fff', fontSize: '10px' }}>
                          {cell === -1 ? '' : cell}
                        </span>
                      </div>
                    </Tooltip>
                  ))}
                </div>
              ))}
            </div>
          </div>
          {/* <div className='gif-wrapper'>
            <Button sx={{ maxWidth: '200px' }} onClick={reloadHandler}>
              Reload gif
            </Button>
            <img
              style={{ width: `${matrix.matrix.length * 16}px` }}
              alt='matrix animated'
              src={`https://fmprocessed.blob.core.windows.net/animated/${matrix.animated}?c${src}`}
            />
          </div> */}
        </Fragment>
      ))}
    </div>
  );
};
