import { useEffect, useState } from 'react';
import { api } from '../api/api';
import axios from 'axios';
import { LoaderMUI } from '../global/loader/loader-mui';
import { useParams } from 'react-router-dom';
import { MatrixWrapper } from '../components/matrix/matrix-wrapper';

import '../components/page-wrapper.css';
import { ErrorMessage } from '../global/error/error';

export const MatrixPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [matrixData, setMatrixData] = useState(null);

  const { runnerId } = useParams();

  const fetchData = async controller => {
    setIsLoading(true);
    setError(false);
    setMatrixData(null);

    try {
      const response = await api.get(
        `results/getMatrix/${runnerId}`
        /* {
            headers: { Authorization: `Token ${token}` },
            signal: controller ? controller.signal : null
          } */
      );

      /* console.log(response); */
      setMatrixData(response.data);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('axios cancel');
      } else {
        setError(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className='page-wrapper'>
      {isLoading && <LoaderMUI />}
      {!isLoading && error && <ErrorMessage />}
      {!isLoading && !error && matrixData && <MatrixWrapper matrixData={matrixData} />}
    </div>
  );
};
