import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as IconSearch } from '../../assets/search.svg';
import { ReactComponent as IconCollapseAll } from '../../assets/collapse-all.svg';

export default function MatrixCheckbox({ flowList, selectedChildren, setSelectedChildren }) {
  /*   const [expandAll, setExpandAll] = useState(false); */

  const allChildren = [...flowList];

  const handleCubeToggle = flowObj => {
    if (selectedChildren.find(flow => flow.id === flowObj.id)) {
      setSelectedChildren(selectedChildren.filter(f => f.id !== flowObj.id));
    } else {
      setSelectedChildren([...selectedChildren, flowObj]);
    }
  };

  const handleSelectAllToggle = () => {
    if (selectedChildren.length === allChildren.length) {
      setSelectedChildren([]);
    } else {
      setSelectedChildren(allChildren);
    }
  };

  return (
    <div className='tree-checkbox'>
      <div className='list-wrapper'>
        <FormGroup className='parent-list'>
          <div className='select-all'>
            <FormControlLabel
              label={'Select all'}
              control={
                <Checkbox
                  //checked={selectedParents.length === parentsList.length}
                  checked={selectedChildren.length === allChildren.length}
                  onChange={handleSelectAllToggle}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    padding: '5px',
                    svg: {
                      width: '18px',
                      height: '18px'
                      // fill: '#fff5e6'
                    },
                    color: '#d0d5dd',
                    '&.Mui-checked': {
                      color: '#efa21e'
                    }
                  }}
                />
              }
              sx={{
                /* width: '100%', */
                '& .MuiFormControlLabel-label': {
                  /* width: '100%', */
                  /* color: '#efa21e' */
                },
                '&.MuiFormControlLabel-root': { mr: 0, ml: 0 },
                //borderBottom: '1px solid #F2F4F7',
                padding: '8px 0'
              }}
            />

            <Box className='right-side'>
              <Box className='value'>
                <Typography>Intensity {/* <span className='unit'>[]</span> */}</Typography>
                {/* <Typography color={'#a2a3a3'}>/</Typography>
                <Typography>
                  Distance <span className='unit'>[m]</span>
                </Typography> */}
              </Box>

              {/*  <Button
                sx={{
                  color: 'secondary.light',
                  '&:hover': {
                    background: '#fff5e6',
                    color: 'primary.main'
                  },
                  padding: '4px',
                  borderRadius: '4px',
                  minWidth: '26px',
                  svg: {
                    minWidth: '18px',
                    width: '18px',
                    height: '18px'
                  }
                }}
                onClick={() => setExpandAll(prev => !prev)}
              >
                <IconCollapseAll />
              </Button> */}
            </Box>
          </div>

          {flowList.map(child => (
            <div className='child-row' key={child.id}>
              <div className='child-label'>
                {/* {isFlow && (
                  <Typography
                    className='from'
                    color='text.secondary'
                    fontSize='0.75rem'
                  >
                    {child.type}
                  </Typography>
                )} */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!selectedChildren.find(f => f.id === child.id)}
                      onChange={() => handleCubeToggle(child)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{
                        padding: '5px',
                        svg: {
                          width: '18px',
                          height: '18px'
                          // fill: '#fff5e6'
                        },
                        color: '#d0d5dd',
                        '&.Mui-checked': {
                          color: '#efa21e'
                        }
                      }}
                    />
                  }
                  /* label={`${child.cube1Index} (${child.cube1Name}) - ${child.cube2Index} (${child.cube2Name})`} */
                  label={
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <div
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      >
                        <div>{child.cube1Index}</div>
                        <div style={{ fontSize: '12px' }}>{child.cube1Name}</div>
                      </div>
                      <div> - </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      >
                        <div>{child.cube2Index}</div>
                        <div style={{ fontSize: '12px' }}>{child.cube2Name}</div>
                      </div>
                    </div>
                  }
                  key={child.id}
                  sx={{
                    '.highlight': {
                      span: {
                        background: '#fff5e6',
                        color: '#dd9933'
                      }
                    },
                    padding: '4px 0',
                    ml: 0
                  }}
                />
              </div>

              <Box className='flow-value'>
                <Typography
                  className='area'
                  color='text.secondary'
                  fontSize='0.875rem' /* mr='12px' */
                >
                  {child.value}
                </Typography>
                {/* <Typography color={'#a2a3a3'}>/</Typography> */}
                {/*  <Typography
                    className='area'
                    color='text.secondary'
                    fontSize='0.875rem' 
                  >
                    {child.length}
                  </Typography> */}
                {/*  <Typography color='#b2b3b4' fontSize='0.75rem'>
                m
              </Typography> */}
              </Box>
            </div>
          ))}
        </FormGroup>
      </div>
    </div>
  );
}
