import { Text, Wireframe, useCursor } from '@react-three/drei';
import { useRef, useState } from 'react';
import { ArrowHelperFun } from './arrow-helper';
import { calculateTextSize } from '../../utils/calculate-size';

export const CubeTwoD = ({
  position,
  dimensions,
  color,
  opacity,
  height,
  isThreeD,
  cubeClickHandler,
  cube,
  cubeSelected,
  floor,
  maxLayoutSize
}) => {
  const mesh = useRef();
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  useCursor(hovered);
  const nameSize = calculateTextSize(maxLayoutSize);

  return (
    <mesh
      ref={mesh}
      position={position}
      userData={{ facilityType: cube.facilityType, department: cube.department }}
      onClick={event => cubeClickHandler(cube)}
      // If a click happened but this mesh wasn't hit we null out the target,
      // This works because missed pointers fire before the actual hits
      onPointerMissed={e => e.type === 'click' && cubeClickHandler(null)}
      onPointerOver={e => (e.stopPropagation(), setHovered(true))}
      onPointerOut={e => setHovered(false)}
      receiveShadow
      transientUpdate
    >
      <boxGeometry args={dimensions} />

      <meshBasicMaterial
        color={cubeSelected ? '#fff' : color}
        transparent
        opacity={opacity}
        depthWrite={false}
        flatShading={false}
        toneMapped={false}
      />

      {cubeSelected && (
        <>
          <ArrowHelperFun
            from={[-cube['x-dim'] / 2, 0, -cube['y-dim'] / 2]}
            to={[+cube['x-dim'] / 2, 0, -cube['y-dim'] / 2]}
            color={0xff0000}
            headLength={1}
            headWidth={0.5}
          />
          <Text
            position={[0, 0, -cube['y-dim'] / 2]}
            rotation={[-Math.PI / 2, 0, 0]}
            text={`-${cube['x-dim']} m-`}
            fontSize={nameSize}
            color={cubeSelected ? '#ff0000' : '#000'}
            depthWrite={false}
            flatShading={false}
            anchorX='center'
            anchorY='top'
            maxWidth={cube['x-dim']}
            /* fontWeight='bold' */
            textAlign='center'
          />
          <ArrowHelperFun
            from={[-cube['x-dim'] / 2, 0, -cube['y-dim'] / 2]}
            to={[-cube['x-dim'] / 2, 0, +cube['y-dim'] / 2]}
            color={0x0000ff}
            headLength={1}
            headWidth={0.5}
          />
          <Text
            position={[-cube['x-dim'] / 2, 0, 0]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            text={`-${cube['y-dim']} m-`}
            fontSize={nameSize}
            color={cubeSelected ? '#0000ff' : '#000'}
            depthWrite={false}
            flatShading={false}
            anchorX='center'
            anchorY='top'
            maxWidth={cube['x-dim']}
            /* fontWeight='bold' */
            textAlign='center'
          />
        </>
      )}

      <Wireframe
        simplify={1}
        stroke={hovered ? '#D0D5DD' : '#565554'}
        thickness={0.01}
        /* backfaceStroke={hovered ? '#3d3d3d' : '#D0D5DD'} */
      />
      {/* <Edges
        linewidth={1}
        scale={1}
        threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
        color='white'
      /> */}
      <Text
        position={[0, height / 2, -dimensions[2] / 4]}
        rotation={[-Math.PI / 2, 0, 0]}
        text={`${cube.name} \n ${cube.area ?? (cube['x-dim'] * cube['y-dim']).toFixed(2)} m²`}
        fontSize={nameSize}
        color={cubeSelected ? '#000' : '#000'}
        depthWrite={false}
        flatShading={false}
        anchorX='center'
        anchorY='middle'
        maxWidth={cube['x-dim']}
        fontWeight='bold'
        textAlign='center'
      />
      {/* {hovered && <Tooltip text={facilityType} />} */}
    </mesh>
  );
};
