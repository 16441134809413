import { createTheme } from '@mui/material';

export const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#efa21e',
      light: '#fff5e6'
    },
    secondary: {
      main: '#5e6278',
      light: '#a3aed0'
    },
    background: {
      default: '#fcfcfd'
    },
    text: {
      primary: '#5e6278',
      secondary: '#a3aed0',
      disabled: '#d0d5dd'
    },
    error: {
      main: '#b42318'
    },
    divider: {
      main: '#eaecf0'
    }
  },
  typography: {
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Inter', 'sans-serif'].join(',')
  }
});
