import { useState } from 'react';
import { ReactComponent as IconChevronRight } from '../../assets/chevron-right.svg';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { Chart } from './pie-chart';

export const Balance = ({ facility, kpis }) => {
  const facilityColors = {
    Manufacturing: '#5E6278', // green
    Assembly: '#9F9694', // orange
    Logistics: '#54504C', // yellow
    'Social areas (break room, toilets)': '#EFA21E', // blue
    'Office, meeting room': '#E6D97A', // brown
    'Conversion area': '#7A2E0E', // black
    'TGA area': '#3E2723', // grey
    'Barrier area': '#AA1E1E', // red
    'Other area': '#FEF7DD' // white
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(prev => !prev);
  };

  return (
    <div className='sidebar-balance'>
      {/* <Accordion
        expanded={expanded}
        onChange={handleChange}
        disableGutters
        elevation={0}
        square
        className='accordion'
      >
        <AccordionSummary
          expandIcon={<IconChevronRight width='18px' height='18px' />}
          aria-controls='panel1d-content'
          id='panel1d-header'
          className='accordion-summary'
        >
          <Typography>Balance</Typography>
        </AccordionSummary>
        <AccordionDetails className='accordion-details'>sidebar-balance</AccordionDetails>
      </Accordion> */}
      <div className='table-kpis'>
        <Typography sx={{ textAlign: 'center', mb: '16px', fontWeight: 600 }}>
          Key Performance Indicator
        </Typography>
        {kpis.map(kpi => (
          <Box className='kpi-row' key={kpi.id}>
            <Typography>
              {kpi.name} <span className='unit'>[{kpi.unit}]</span>
            </Typography>
            <Typography>{kpi.value}</Typography>
          </Box>
        ))}
      </div>
      <div className='chart'>
        <Typography sx={{ textAlign: 'center', mb: '16px', fontWeight: 600 }}>
          Area Balance
        </Typography>
        <Chart facility={facility} />
      </div>

      {/*  <div className='table-legend'>
        <Box className='legend-header'>
          <Typography>Facility Type</Typography>
          <Box className='value'>
            <Typography width='100px' mr='12px'>
              Area <span className='unit'>[m²]</span>
            </Typography>
            <Typography width='80px'>Share</Typography>
          </Box>
        </Box>
        {legend.map(kpi => (
          <Box className='legend-row'>
            <Typography>{kpi.name}</Typography>
            <Box className='value'>
              <Typography width='100px' mr='12px'>
                {kpi.value}
              </Typography>
              <Typography width='80px'>{kpi.value}%</Typography>
            </Box>
          </Box>
        ))}
      </div> */}
    </div>
  );
};
