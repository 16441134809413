import { Alert } from '@mui/material';
import './error.css';

export const ErrorMessage = () => {
  return (
    <div className='error'>
      <Alert
        severity='error'
        style={{
          fontSize: '14px',
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Data Not Found
      </Alert>
    </div>
  );
};
