import { Fragment, useEffect, useState } from 'react';
import { ThreeDModel } from './3d-model';
import { styled } from '@mui/material/styles';
import { Box, Drawer, Button, Typography, Tooltip, tooltipClasses } from '@mui/material';
import { ThreeDSidebarTabs } from './3d-sidebar-tabs';
import { exportExcel } from '../utils/export-excel';
import SidebarSelectionState from '../context/sidebar-selection';

import { ReactComponent as IconChevronLeft } from '../assets/chevron-left.svg';
import { ReactComponent as IconHome } from '../assets/home.svg';
import { ReactComponent as IconGrid } from '../assets/grid.svg';
import { ReactComponent as IconImage } from '../assets/image.svg';
import { ReactComponent as IconClose } from '../assets/x.svg';
import { ReactComponent as IconFlow } from '../assets/programming-arrows.svg';

import './page-wrapper.css';
import { LuckyOrangeWrapper } from './luckyorange_wrapper';
import { luckyorangeTrackEven } from '../utils/luckyorange-events';

/* const LuckyOrange = require('@luckyorange/server'); */

const drawerWidth = 420;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen
  }),
  /* width: 'calc(100% - 420px)', */
  marginRight: 0,

  width: '100%',
  ...(!open && {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth
    /* width: '100%' */
  }),
  position: 'relative'
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.default
    /*   boxShadow: theme.shadows[1],
     fontSize: 11 */
  }
}));

export const ThreeDContent = ({ layoutData, user }) => {
  /*  const luckyorange = new LuckyOrange({ siteId: '90f1dda1' });
  luckyorange.visitors.identify(user.id, { email: user.email }); */
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleDrawerOpen = () => {
    luckyorangeTrackEven('onCloseSidebar');
    setSidebarOpen(true);
  };

  const handleDrawerClose = () => {
    luckyorangeTrackEven('onOpenSidebar');
    setSidebarOpen(false);
  };

  const [reset, setReset] = useState(false);
  const [downloadImage, setDownloadImage] = useState(0);
  const [downloadOBJ, setDownloadOBJ] = useState(0);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [showFlow, setShowFlow] = useState(false);
  const [isThreeD, setIsThreeD] = useState(true);
  const [cubeDetails, setCubeDetails] = useState(null);
  const [zoomIn, setZoomIn] = useState(0);
  const [zoomOut, setZoomOut] = useState(0);

  const cubeClickHandler = cube => {
    luckyorangeTrackEven('onCube');
    setCubeDetails(cube);
  };

  const showFlowHandler = () => {
    setIsThreeD(showFlow);
    setShowFlow(prev => !prev);
  };

  const allFlows = layoutData.flows.flatMap(parent =>
    parent.children.map(cube => cube.connection_id)
  );
  const allCubes = layoutData.cubes.flatMap(parent => parent.children.map(cube => cube.id));

  window.LOQ = window.LOQ || [];
  window.LOQ.push([
    'ready',
    async LO => {
      await LO.$internal.ready('visitor');
      LO.visitor.identify({ email: user.email });
    }
  ]);

  return (
    <SidebarSelectionState cubes={allCubes} flows={allFlows}>
      <Box className='threed-content'>
        <Main open={sidebarOpen} className='threed-area'>
          <Button
            color='inherit'
            aria-label='open drawer'
            onClick={sidebarOpen ? handleDrawerClose : handleDrawerOpen}
            edge='start'
            className='sidebar-toggle'
            sx={{
              right: sidebarOpen ? `${drawerWidth}px` : '0px',
              transition: sidebarOpen
                ? 'right 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
                : 'right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              svg: {
                transform: sidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
              }
            }}
          >
            <IconChevronLeft />
          </Button>
          <ThreeDModel
            layoutData={layoutData.threeDCubes}
            reset={reset}
            isThreeD={isThreeD}
            showGrid={showGrid}
            showFlow={showFlow}
            downloadImage={downloadImage}
            downloadOBJ={downloadOBJ}
            cubeClickHandler={cubeClickHandler}
            cubeDetails={cubeDetails}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
          />
          <Box className='left-control-box'>
            <Box className='control-btn-group'>
              <LightTooltip title='Zoom In' placement='right'>
                <Button
                  sx={{
                    color: 'secondary.main',
                    fontSize: '20px'
                  }}
                  onClick={() => {
                    setZoomIn(prev => prev + 1);
                    luckyorangeTrackEven('onZoomIn');
                  }}
                >
                  +
                </Button>
              </LightTooltip>
              <LightTooltip title='Zoom Out' placement='right'>
                <Button
                  sx={{
                    color: 'secondary.main',
                    fontSize: '20px'
                  }}
                  onClick={() => {
                    setZoomOut(prev => prev + 1);
                    luckyorangeTrackEven('onZoomOut');
                  }}
                >
                  -
                </Button>
              </LightTooltip>
            </Box>
            <LightTooltip title='Reset View' placement='right'>
              <Button
                className='control-btn'
                sx={{
                  /* position: 'absolute',
                top: '160px',
                left: '20px', */
                  color: 'secondary.main'
                  /*           borderRadius: '6px',
            background: '#fcfcfd',
            minWidth: 0,
            padding: '8px',
            '&:hover': {
              color: 'primary.main'
            } */
                }}
                onClick={() => {
                  setReset(prev => !prev);
                  luckyorangeTrackEven('onResetView');
                }}
              >
                <IconHome />
              </Button>
            </LightTooltip>
          </Box>

          <Box
            className='top-control-box'
            sx={{
              right: sidebarOpen ? `${drawerWidth + 40}px` : '40px',
              transition: sidebarOpen
                ? 'right 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
                : 'right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
            }}
          >
            <Box className='control-btn-group'>
              <LightTooltip title='2D View' placement='bottom'>
                <Button
                  onClick={() => {
                    luckyorangeTrackEven('onView2D');
                    setIsThreeD(false);
                  }}
                  sx={{
                    color: isThreeD ? 'secondary.main' : 'primary.main'
                  }}
                >
                  2D
                </Button>
              </LightTooltip>
              <LightTooltip title='3D View' placement='bottom'>
                <Button
                  onClick={() => {
                    luckyorangeTrackEven('onView3D');
                    if (showFlow) {
                      setShowFlow(false);
                    }
                    setIsThreeD(true);
                  }}
                  sx={{
                    color: isThreeD ? 'primary.main' : 'secondary.main'
                  }}
                >
                  3D
                </Button>
              </LightTooltip>
            </Box>
            <LightTooltip title='Apply Grid' placement='bottom'>
              <Button
                className='control-btn'
                sx={{
                  color: showGrid ? 'primary.main' : 'secondary.main'
                }}
                onClick={() => {
                  luckyorangeTrackEven('onGrid');
                  setShowGrid(prev => !prev);
                }}
              >
                <IconGrid />
              </Button>
            </LightTooltip>

            <LightTooltip title='Apply Flow' placement='bottom'>
              <Button
                className='control-btn'
                sx={{
                  color: showFlow ? 'primary.main' : 'secondary.main'
                }}
                onClick={() => {
                  luckyorangeTrackEven('onFlow');
                  showFlowHandler();
                }}
              >
                <IconFlow />
              </Button>
            </LightTooltip>

            <LightTooltip title='Download Screenshot' placement='bottom'>
              <Button
                className='control-btn'
                sx={{
                  color: 'secondary.main'
                }}
                onClick={() => {
                  luckyorangeTrackEven('onImage');
                  setDownloadImage(prev => prev + 1);
                }}
              >
                <IconImage />
              </Button>{' '}
            </LightTooltip>
            <div className='download-btn-wrapper'>
              <Button
                disabled={true}
                className='control-btn'
                sx={{
                  color: /* 'primary.main' */ 'secondary.main',
                  textTransform: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  textWrap: 'nowrap'
                }}
                onClick={() => {
                  luckyorangeTrackEven('onExport3D');
                  setDownloadOBJ(prev => prev + 1);
                }}
              >
                EXPORT 3D {/* (.gltf) */}
              </Button>
              <div className='badge-coming-soon'>coming soon</div>
            </div>

            <Button
              className='control-btn'
              sx={{
                color: /* 'primary.main' */ 'secondary.main',
                textWrap: 'nowrap'
              }}
              onClick={() => {
                luckyorangeTrackEven('onExportExcel');
                exportExcel(layoutData.excel);
              }}
            >
              Export Excel
            </Button>
          </Box>
          {cubeDetails && (
            <Box
              className='bottom-details-box'
              sx={{
                bottom: sidebarOpen ? '20px' : '86px',
                right: sidebarOpen ? `${drawerWidth + 40}px` : '40px',
                transition: sidebarOpen
                  ? 'right 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
                  : 'right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
              }}
            >
              <div className='box-header'>
                <Typography color='primary.main'>{cubeDetails.name}</Typography>
                <Button onClick={() => setCubeDetails(null)}>
                  <IconClose width='16px' height='16px' />
                </Button>
              </div>
              <div className='box-content'>
                <div className='detail-item'>
                  <Typography className='detail-name'>
                    Area <span className='unit'>[m²]</span>
                  </Typography>
                  <Typography className='detail-value'>
                    {cubeDetails.area ?? (cubeDetails['x-dim'] * cubeDetails['y-dim']).toFixed(2)}
                  </Typography>
                </div>
                <div className='detail-item'>
                  <Typography className='detail-name'>
                    Dimension <span className='unit'>[m]</span>
                  </Typography>
                  <div className='dim-wrapper'>
                    <div className='dim'>
                      <Typography className='dim-name' color={'#ff0000'}>
                        X
                      </Typography>
                      <Typography className='detail-value'>{cubeDetails['x-dim']}</Typography>
                    </div>
                    <div className='dim'>
                      <Typography className='dim-name' color={'#008000'}>
                        Y
                      </Typography>
                      <Typography className='detail-value'>{cubeDetails['z-dim']}</Typography>
                    </div>
                    <div className='dim'>
                      <Typography className='dim-name' color={'#0000ff'}>
                        Z
                      </Typography>
                      <Typography className='detail-value'>{cubeDetails['y-dim']}</Typography>
                    </div>
                  </div>
                </div>
                <div className='detail-item'>
                  <Typography className='detail-name'>Department</Typography>
                  <Typography className='detail-value'>{cubeDetails.depName}</Typography>
                </div>
                <div className='detail-item'>
                  <Typography className='detail-name'>Facility Type</Typography>
                  <Typography className='detail-value'>{cubeDetails.facility_type}</Typography>
                </div>
                <div></div>
              </div>
            </Box>
          )}
        </Main>
        <Drawer
          sx={{
            width: /* sidebarOpen ? drawerWidth : 0 */ drawerWidth,
            flexShrink: 0,
            /* position: 'absolute',
            top: 0,
            right: 0, */
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              overflow: 'hidden',
              borderLeft: 'none'
            }
          }}
          variant='persistent'
          anchor='right'
          open={sidebarOpen}
        >
          <ThreeDSidebarTabs
            cubesList={layoutData.cubes}
            flowsList={layoutData.flows}
            facility={layoutData.facility}
            kpis={layoutData.kpis}
          />
        </Drawer>
      </Box>
    </SidebarSelectionState>
  );
};
