import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useState } from 'react';
import ParentCheckbox from './parent-checkbox';
import { ReactComponent as IconSearch } from '../../../assets/search.svg';
import { ReactComponent as IconCollapseAll } from '../../../assets/collapse-all.svg';
import { luckyorangeTrackEven } from '../../../utils/luckyorange-events';

export default function TreeCheckbox({
  parentsList,
  selectedChildren,
  setSelectedChildren,
  isFlow
}) {
  const [expandAll, setExpandAll] = useState(false);
  const idName = isFlow ? 'connection_id' : 'id';

  const [search, setSearch] = useState('');

  const setSearchHandler = e => {
    luckyorangeTrackEven(`search-${isFlow ? 'flow' : 'cube'}`);
    const searchText = e.target.value;
    setSearch(searchText);
  };

  const filteredParents = search
    ? parentsList.filter(
        item => !!item.children.find(c => c.name.toLowerCase().includes(search.toLowerCase())) /* ||
          item.name.toLowerCase().includes(search.toLowerCase()) */
      )
    : parentsList;

  const filteredCubes = filteredParents.flatMap(parent =>
    parent.children
      .filter(c => c.name.toLowerCase().includes(search.toLowerCase()))
      .map(cube => cube[idName])
  );

  const allChildren = parentsList.flatMap(parent => parent.children.map(cube => cube[idName]));

  /* const [selectedChildren, setSelectedChildren] = useState(allChildren); */

  const handleCubeToggle = cubeID => {
    if (selectedChildren.includes(cubeID)) {
      setSelectedChildren(selectedChildren.filter(c => c !== cubeID));
    } else {
      setSelectedChildren([...selectedChildren, cubeID]);
    }
  };

  const handleDepartmentToggle = (event, department) => {
    const isChecked = event.target.checked;

    const departmentCubes = search
      ? department.children
          .filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
          .map(cube => cube[idName])
      : department.children.map(cube => cube[idName]);

    if (isChecked) {
      // Add department cubes to the selected cubes
      setSelectedChildren([...selectedChildren, ...departmentCubes]);
    } else {
      // Remove department cubes from the selected cubes
      setSelectedChildren(selectedChildren.filter(cube => !departmentCubes.includes(cube)));
    }
  };

  const filteredSelectedCubes = search
    ? selectedChildren.filter(c => filteredCubes.includes(c))
    : selectedChildren;

  const handleSelectAllToggle = () => {
    if (search) {
      if (filteredSelectedCubes.length === filteredCubes.length) {
        setSelectedChildren(prev => prev.filter(c => !filteredSelectedCubes.includes(c)));
      } else {
        setSelectedChildren([...selectedChildren, ...filteredCubes]);
      }
    } else {
      if (selectedChildren.length === allChildren.length) {
        setSelectedChildren([]);
      } else {
        setSelectedChildren(allChildren);
      }
    }
  };

  return (
    <div className='tree-checkbox'>
      <div className='input-container' onKeyDown={e => e.stopPropagation()}>
        <IconSearch width='18px' height='18px' color='#a3aed0' />
        <input
          type='text'
          value={search}
          onChange={e => setSearchHandler(e)}
          placeholder='Search Layout Block Name'
        />
      </div>
      <div className='list-wrapper'>
        <FormGroup className='parent-list'>
          <div className='select-all'>
            <FormControlLabel
              label={'Select all'}
              control={
                <Checkbox
                  //checked={selectedParents.length === parentsList.length}
                  checked={
                    search
                      ? filteredSelectedCubes.length === filteredCubes.length
                      : selectedChildren.length === allChildren.length
                  }
                  onChange={handleSelectAllToggle}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    padding: '5px',
                    svg: {
                      width: '18px',
                      height: '18px'
                      // fill: '#fff5e6'
                    },
                    color: '#d0d5dd',
                    '&.Mui-checked': {
                      color: '#efa21e'
                    }
                  }}
                />
              }
              sx={{
                /* width: '100%', */
                '& .MuiFormControlLabel-label': {
                  /* width: '100%', */
                  /* color: '#efa21e' */
                },
                '&.MuiFormControlLabel-root': { mr: 0, ml: 0 },
                //borderBottom: '1px solid #F2F4F7',
                padding: '8px 0'
              }}
            />

            <Box className='right-side'>
              {isFlow && (
                <Box className='value'>
                  <Typography>Intensity {/* <span className='unit'>[]</span> */}</Typography>
                  <Typography color={'#a2a3a3'}>/</Typography>
                  <Typography>
                    Distance <span className='unit'>[m]</span>
                  </Typography>
                </Box>
              )}
              <Button
                sx={{
                  color: 'secondary.light',
                  '&:hover': {
                    background: '#fff5e6',
                    color: 'primary.main'
                    /* svg: {
                  stroke: 'primary.main'
                } */
                  },
                  padding: '4px',
                  borderRadius: '4px',
                  minWidth: '26px',
                  svg: {
                    minWidth: '18px',
                    width: '18px',
                    height: '18px'
                    /* stroke: '#A3AED0' */
                  }
                }}
                onClick={() => setExpandAll(prev => !prev)}
              >
                <IconCollapseAll /* width='18px' height='18px' */ /*  stroke='#A3AED0' */ />
              </Button>
            </Box>
          </div>

          {filteredParents.map(parent => (
            <ParentCheckbox
              parent={parent}
              search={search}
              key={parent.id}
              handleDepartmentToggle={handleDepartmentToggle}
              handleCubeToggle={handleCubeToggle}
              selectedCubesNew={selectedChildren}
              expandAll={expandAll}
              isFlow={isFlow}
            />
          ))}
        </FormGroup>
      </div>
    </div>
  );
}
