import { ReactComponent as IconChevronRight } from '../../assets/chevron-right.svg';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import TreeCheckbox from './select-tree/tree-checkbox';
import { useFlowsState } from '../../context/sidebar-selection';

export const FlowSelection = ({ flowsList }) => {
  const [selectedFlows, setSelectedFlows] = useFlowsState();

  /*   const [expanded, setExpanded] = useState(false);
  const handleChange = () => {
    setExpanded(prev => !prev);
  }; */

  return (
    <div className='sidebar-flow'>
      {/* <Accordion
        expanded={expanded}
        onChange={handleChange}
        disableGutters
        elevation={0}
        square
        className='accordion'
      >
        <AccordionSummary
          expandIcon={<IconChevronRight width='18px' height='18px' />}
          aria-controls='panel1d-content'
          id='panel1d-header'
          className='accordion-summary'
        >
          <Typography>Flow</Typography>
        </AccordionSummary>
        <AccordionDetails className='accordion-details'>sidebar-flow</AccordionDetails>
      </Accordion> */}

      <TreeCheckbox
        parentsList={flowsList}
        isFlow={true}
        selectedChildren={selectedFlows}
        setSelectedChildren={setSelectedFlows}
      />
    </div>
  );
};
