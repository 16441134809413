import {
  GizmoHelper,
  GizmoViewport,
  PivotControls,
  Text,
  Wireframe,
  useCursor
} from '@react-three/drei';
import { useRef, useState } from 'react';
import Triangle from './triangle';
import * as THREE from 'three';
import { ArrowHelperFun } from './arrow-helper';
import { CustomArrow } from './custom-arrow';
import { CustomLine } from './line';
import { calculateTextSize } from '../../utils/calculate-size';

export const Cube = ({
  position,
  dimensions,
  color,
  opacity,
  height,
  isThreeD,
  cubeClickHandler,
  cube,
  cubeSelected,
  floor,
  border,
  maxLayoutSize
}) => {
  const mesh = useRef();
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  useCursor(hovered);

  var v1 = new THREE.Vector3(0, 20, 0);
  var v2 = new THREE.Vector3(0, 0, 0);
  var v3 = new THREE.Vector3(20, 0, 0);

  const nameSize = calculateTextSize(maxLayoutSize);

  return (
    <>
      {/* <PivotControls
        rotation={[0, 0, 0]}
        anchor={[-1, -1, -1]}
        scale={75}
        depthTest={false}
        fixed
        lineWidth={2}
        disableRotations
        disableScaling
        disableSliders
      > */}
      <mesh
        ref={mesh}
        position={position}
        userData={{ facilityType: cube.facilityType, department: cube.department }}
        onClick={event => cubeClickHandler(cube)}
        // If a click happened but this mesh wasn't hit we null out the target,
        // This works because missed pointers fire before the actual hits
        onPointerMissed={e => e.type === 'click' && cubeClickHandler(null)}
        onPointerOver={e => (e.stopPropagation(), setHovered(true))}
        onPointerOut={e => setHovered(false)}
        receiveShadow
        transientUpdate
      >
        <boxGeometry args={dimensions} />
        <meshBasicMaterial
          color={cubeSelected ? '#fff' : color}
          /* color={color} */
          transparent={true}
          opacity={opacity}
          depthWrite={false}
          flatShading={false}
          toneMapped={false}
        />

        {cubeSelected && (
          <>
            <ArrowHelperFun
              from={[-cube['x-dim'] / 2, -cube['z-dim'] / 2, -cube['y-dim'] / 2]}
              to={[-cube['x-dim'] / 2, cube['z-dim'] / 2, -cube['y-dim'] / 2]}
              color={0x008000}
              headLength={1}
              headWidth={0.5}
            />
            <Text
              position={[-cube['x-dim'] / 2, 0, -cube['y-dim'] / 2]}
              rotation={[0, 0, Math.PI / 2]}
              text={`-${cube['z-dim']} m-`}
              fontSize={nameSize}
              color={cubeSelected ? '#008000' : '#000'}
              depthWrite={false}
              flatShading={false}
              anchorX='center'
              anchorY='left'
              maxWidth={cube['x-dim']}
              /* fontWeight='bold' */
              textAlign='center'
            />
            <ArrowHelperFun
              from={[-cube['x-dim'] / 2, -cube['z-dim'] / 2, -cube['y-dim'] / 2]}
              to={[+cube['x-dim'] / 2, -cube['z-dim'] / 2, -cube['y-dim'] / 2]}
              color={0xff0000}
              headLength={1}
              headWidth={0.5}
            />
            <Text
              position={[0, -cube['z-dim'] / 2, -cube['y-dim'] / 2]}
              rotation={[-Math.PI / 2, 0, 0]}
              text={`-${cube['x-dim']} m-`}
              fontSize={nameSize}
              color={cubeSelected ? '#ff0000' : '#000'}
              depthWrite={false}
              flatShading={false}
              anchorX='center'
              anchorY='top'
              maxWidth={cube['x-dim']}
              /* fontWeight='bold' */
              textAlign='center'
            />
            <ArrowHelperFun
              from={[-cube['x-dim'] / 2, -cube['z-dim'] / 2, -cube['y-dim'] / 2]}
              to={[-cube['x-dim'] / 2, -cube['z-dim'] / 2, +cube['y-dim'] / 2]}
              color={0x0000ff}
              headLength={1}
              headWidth={0.5}
            />
            <Text
              position={[-cube['x-dim'] / 2, -cube['z-dim'] / 2, 0]}
              rotation={[-Math.PI / 2, 0, Math.PI / 2]}
              text={`-${cube['y-dim']} m-`}
              fontSize={nameSize}
              color={cubeSelected ? '#0000ff' : '#000'}
              depthWrite={false}
              flatShading={false}
              anchorX='center'
              anchorY='top'
              maxWidth={cube['x-dim']}
              /* fontWeight='bold' */
              textAlign='center'
            />

            {/*  <GizmoViewport disabled hideNegativeAxes scale={15} position={[0, 0, 0]} /> */}

            {/* <axesHelper
              args={dimensions}
              
            /> */}
            {/*  <CustomLine
              points={[
                [cube['x-pos'], 0, cube['y-pos']],
                [cube['x-pos'], cube['z-dim'], cube['y-pos']]
              ]}
            /> */}
          </>
        )}

        {/*  <Triangle vertices={[v1, v2, v3]} position={[0, 0, 0]} /> */}
        {/* '#D0D5DD' '#3d3d3d'*/}

        <Wireframe
          simplify={1}
          /* stroke={hovered ? '#3d3d3d' : border}
        thickness={0.02}
        backfaceStroke={hovered ? '#3d3d3d' : border} */
          stroke={hovered ? '#D0D5DD' : '#565554'}
          thickness={0.01}
          backfaceStroke={hovered ? '#D0D5DD' : '#565554'}
          /* backfaceStroke={hovered ? '#3d3d3d' : '#D0D5DD'} */
        />
        {/* <Edges
        linewidth={1}
        scale={1}
        threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
        color='white'
      /> */}
        <Text
          position={[0, height / 2, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          text={`${cube.name} \n ${cube.area ?? (cube['x-dim'] * cube['y-dim']).toFixed(2)} m²`}
          fontSize={nameSize}
          color={cubeSelected ? '#000' : '#000'}
          depthWrite={false}
          flatShading={false}
          anchorX='center'
          anchorY='middle'
          maxWidth={cube['x-dim']}
          fontWeight='bold'
          textAlign='center'
        />
        {/* <CustomArrow
          from={[-cube['x-dim'] / 2, -cube['z-dim'] / 2, -cube['y-dim'] / 2]}
          to={[-cube['x-dim'] / 2, cube['z-dim'], -cube['y-dim'] / 2]}
          color={0x649f8d}
          thickness={0.25}
        /> */}
        {/* <Text
        position={[0, height / 2, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        fontSize={1.5}
        color={cubeSelected ? '#000' : '#000'}
        depthWrite={false}
        flatShading={false}
        anchorX='center'
        anchorY='middle'
      >
        {cube.area ?? (cube['x-dim'] * cube['y-dim']).toFixed(2)}
      </Text> */}
        {/* {hovered && <Tooltip text={facilityType} />} */}
      </mesh>

      {/* </PivotControls> */}
    </>
  );
};
