import { Canvas } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import {
  Bounds,
  Edges,
  OrbitControls,
  PerspectiveCamera,
  Text,
  Wireframe
} from '@react-three/drei';
import { DoubleSide } from 'three';
import { GLTFExporter, OBJExporter } from 'three/examples/jsm/Addons.js';
import { facilityColors } from '../utils/convert-cubes';
import { Path } from './model/path';

const Cube = ({ position, dimensions, color, facilityType, department, opacity, name, height }) => {
  const mesh = useRef();
  return (
    <mesh ref={mesh} position={position} userData={{ facilityType, department }}>
      <boxGeometry args={dimensions} />
      <meshBasicMaterial
        color={color}
        transparent
        opacity={opacity}
        depthWrite={false}
        flatShading={false}
      />

      <Wireframe simplify={1} stroke='#fff1f1' thickness={0.015} />

      {/*  <Text
        position={[0, height / 2, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        text={name}
        fontSize={0.5}
        color={'black'}
        depthWrite={false}
        flatShading={false}
      /> */}
    </mesh>
  );
};

const Floor = ({ property }) => {
  /* const [ref] = usePlane(() => ({ mass: 0, ...props }), useRef()); */
  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} /* ref={ref} */ receiveShadow>
      <planeGeometry args={property} />
      <meshBasicMaterial
        /* color='#efa21e' */ color='#fff'
        side={DoubleSide}
        depthWrite={false}
        flatShading={false}
      />
    </mesh>
  );
};

export const ThreeDImage = ({ layoutData, isThreeD }) => {
  console.log(layoutData);
  const orbitRef = useRef();
  const canvasRef = useRef();
  const downloadGroupRef = useRef();

  // prinScreen
  const downloadScreenshot = () => {
    const image = canvasRef.current
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    console.log(image);
    const a = document.createElement('a');
    a.setAttribute('download', 'screenshot.png');
    a.setAttribute('href', image);
    /* a.click(); */
  };

  useEffect(() => {
    downloadScreenshot();
  }, []);

  return (
    <Canvas /* camera={{ position: [0, 0, 10] }} */
      style={{
        width: '100%',
        height: '100%',
        // background: '#333333',
        background: '#fff'
      }}
      ref={canvasRef}
      gl={{ preserveDrawingBuffer: true }}
    >
      {/* <color attach='background' args={['#f8f7f6']} /> */}
      {/* <ambientLight intensity={0.5} /> */}
      {/* <directionalLight intensity={0.5} position={[10, 10, 5]} /> */}
      <PerspectiveCamera
        position={[0, 50, 0]}
        /* zoom={1} */
        makeDefault
      />

      {/* <directionalLight position={[0, 0, 2]} /> */}
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
      <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />

      <OrbitControls ref={orbitRef} makeDefault />
      <Bounds fit clip observe margin={1} maxDuration={0}>
        <group ref={downloadGroupRef}>
          <Floor property={[layoutData.project['x-dim'], layoutData.project['y-dim']]} />
          <group position={[-layoutData.project['x-dim'] / 2, 0, -layoutData.project['y-dim'] / 2]}>
            {layoutData.cubes.map((cube, index) => (
              <Cube
                key={cube.id}
                position={[cube['x-pos'] + cube['x-dim'] / 2, 0, cube['y-pos'] + cube['y-dim'] / 2]}
                dimensions={[cube['x-dim'], 0.05, cube['y-dim']]}
                height={0.1}
                color={cube.facility_type ? facilityColors[cube.facility_type] : '#c6c7c8'}
                facilityType={cube.facility_type}
                department={cube.department}
                opacity={0.9}
                name={cube.name}
                isThreeD={isThreeD}
                cubeItem={cube}
                /* opacity={cubesSelected.includes(cube.name) ? 0.4 : 0.2}
            name={cubesSelected.includes(cube.name) ? cube.name : ''} */
              />
            ))}
            {layoutData.path &&
              layoutData.path.map((itemPath, index) =>
                itemPath.map(cell => (
                  <Path
                    key={`path-cell${cell[0]}${cell[1]}`}
                    position={[
                      cell[1] + layoutData.project.cell_size / 2,
                      0,
                      cell[0] + layoutData.project.cell_size / 2
                    ]}
                    cellSize={layoutData.project.cell_size}
                  />
                ))
              )}
          </group>
        </group>
      </Bounds>
    </Canvas>
  );
};
