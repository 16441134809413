export const luckyorangeTrackEven = eventName => {
  window.LOQ = window.LOQ || [];
  window.LOQ.push([
    'ready',
    async LO => {
      // Track an event
      await LO.$internal.ready('events');
      LO.events.track(eventName);
    }
  ]);
};
