import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useState } from 'react';

export const ChildrenList = ({
  childrenList,
  search,
  handleCubeToggle,
  selectedCubesNew,
  isFlow
}) => {
  const idName = isFlow ? 'connection_id' : 'id';

  const replace = (name, searchText) => {
    const splitedTitle = name.split(new RegExp(`(${searchText})`, 'gi'));

    return (
      <span className='highlight'>
        {splitedTitle.map(item =>
          item.toLowerCase() === searchText.toLowerCase() ? <span>{item}</span> : item
        )}
      </span>
    );
  };

  const filteredChildren = search
    ? childrenList.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
    : childrenList;

  return (
    <>
      {filteredChildren.map(child => (
        <div className='child-row' key={child.id + isFlow ? child.type : ''}>
          <div className='child-label'>
            {isFlow && (
              <Typography
                className='from'
                color='text.secondary'
                fontSize='0.75rem' /* mr='12px' */
              >
                {child.type}
              </Typography>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedCubesNew.includes(child[idName])}
                  onChange={() => handleCubeToggle(child[idName])}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    padding: '5px',
                    svg: {
                      width: '18px',
                      height: '18px'
                      // fill: '#fff5e6'
                    },
                    color: '#d0d5dd',
                    '&.Mui-checked': {
                      color: '#efa21e'
                    }
                  }}
                />
              }
              label={search ? replace(child.name, search) : child.name}
              key={child[idName]}
              sx={{
                '.highlight': {
                  span: {
                    background: '#fff5e6',
                    color: '#dd9933'
                  }
                },
                padding: '4px 0',
                ml: 0
              }}
            />
          </div>

          {isFlow && (
            <Box className='flow-value'>
              <Typography
                className='area'
                color='text.secondary'
                fontSize='0.875rem' /* mr='12px' */
              >
                {child.intensity}
              </Typography>
              <Typography color={'#a2a3a3'}>/</Typography>
              <Typography
                className='area'
                color='text.secondary'
                fontSize='0.875rem' /* mr='12px' */
              >
                {child.length}
              </Typography>
              {/*  <Typography color='#b2b3b4' fontSize='0.75rem'>
                m
              </Typography> */}
            </Box>
          )}
        </div>
      ))}
    </>
  );
};
