//#D19E9B #AFB89B #E7E3D8 #D7BEA0 #A2ABB0 #708188 #C4C2B5 #C8B5B7 #9C7B82 #8698AE #A5B2C2 #F2B694 #C39186

/* export const facilityColors = {
  Manufacturing: '#8698AE', // green
  Assembly: '#D7BEA0', // orange
  Logistics: '#C4C2B5', // yellow
  'Social areas (break room, toilets)': '#A5B2C2', // blue '#6A819E'
  'Office, meeting room': '#AFB89B', // brown '#7EAFAC
  'Conversion area': '#E7E3D8', // black
  'TGA area': '#C39186', // grey
  'Barrier area': '#BAAB4C', // red '#A3B77A
  'Other area': '#bdbec2' // white
};

export const facilityBorder = {
  Manufacturing: '#5E6278', // green
  Assembly: '#DD9933', // orange
  Logistics: '#9F9694', // yellow
  'Social areas (break room, toilets)': '#6A819E', // blue '#6A819E'
  'Office, meeting room': '#649F8D', // brown '#7EAFAC #597B7D
  'Conversion area': '#D7BEA0', // black
  'TGA area': '#866262', // grey
  'Barrier area': '#BAAB4C', // red '#A3B77A
  'Other area': '#bdbec2' // white
}; */

//brand colors with border

/* export const facilityColors = {
  Manufacturing: '#8a8d9f', // green
  Assembly: '#d5ad7d', // orange
  Logistics: '#b8b3b3', // yellow
  'Social areas (break room, toilets)': '#b3b7cb', // blue '#6A819E'
  'Office, meeting room': '#85a0a4', // brown '#7EAFAC
  'Conversion area': '#ddd0c1', // black
  'TGA area': '#a78d90', // grey
  'Barrier area': '#c1b989', // red '#A3B77A
  'Other area': '#d0d0d2' // white
};*/

export const facilityBorder = {
  Manufacturing: '#5E6278', // green
  Assembly: '#DD9933', // orange
  Logistics: '#9F9694', // yellow
  'Social areas (break room, toilets)': '#929BBA', // blue '#6A819E'
  'Office, meeting room': '#597B7D', // brown '#7EAFAC
  'Conversion area': '#D7BEA0', // black
  'TGA area': '#866262', // grey
  'Barrier area': '#BAAB4C', // red '#A3B77A
  'Other area': '#bdbec2' // white
};

//brand colors

export const facilityColors = {
  Manufacturing: '#5E6278', // green
  Assembly: '#DD9933', // orange
  Logistics: '#9F9694', // yellow
  'Social areas (break room, toilets)': '#929BBA', // blue '#6A819E'
  'Office, meeting room': '#708188', // brown '#7EAFAC
  'Conversion area': '#D7BEA0', // black
  'TGA area': '#866262', // grey
  'Barrier area': '#BAAB4C', // red '#A3B77A
  'Other area': '#bdbec2' // white
};

//pastel colors with border

/* export const facilityColors = {
  Manufacturing: '#D9CEDE', // green
  Assembly: '#D3D3DF', // orange
  Logistics: '#D7E7E4', // yellow
  'Social areas (break room, toilets)': '#F8CECC', // blue '#6A819E'
  'Office, meeting room': '#FEF7DD', // brown
  'Conversion area': '#FFE6CC', // black
  'TGA area': '#D5E8D4', // grey
  'Barrier area': '#DAE8FC', // red
  'Other area': '#bdbec2' // white
}; */
/* export const facilityBorder = {
  Manufacturing: '#5C4069', // green
  Assembly: '#4F5771', // orange
  Logistics: '#649F8D', // yellow
  'Social areas (break room, toilets)': '#BF6562', // blue '#6A819E'
  'Office, meeting room': '#F5E252', // brown
  'Conversion area': '#D9A41F', // black
  'TGA area': '#8CB875', // grey
  'Barrier area': '#7B99C5', // red
  'Other area': '#bdbec2' // white
}; */

/* export const facilityColors = {
  Manufacturing: '#5E6278', // green
  Assembly: '#DD9933', // orange
  Logistics: '#9F9694', // yellow
  'Social areas (break room, toilets)': '#929BBA', // blue '#6A819E'
  'Office, meeting room': '#343745', // brown
  'Conversion area': '#AC5A32', // black
  'TGA area': '#565554', // grey
  'Barrier area': '#A06F26', // red
  'Other area': '#bdbec2' // white
}; */

export function convertCubes(data, index) {
  const version = data.project.version === 2 ? 1 : 2;
  /*  const cubesWithIndex = data.cubes.map((c, i) => ({...c, index: i})) */
  /* find position for used area */
  let firstX = null;
  let lastX = null;
  let firstY = null;
  let lastY = null;

  if (version === 2) {
    /* find first Y */
    for (let y = 0; y < data.matrix.length; y++) {
      for (let x = 0; x < data.matrix[y].length - 1; x++) {
        if (data.matrix[y][x] >= 0 && firstY === null) {
          firstY = y;
        }
      }
      if (firstY !== null) {
        break;
      }
    }

    /* find last Y */
    for (let y = data.matrix.length - 1; y >= 0; y--) {
      for (let x = data.matrix[y].length - 1; x >= 0; x--) {
        if (data.matrix[y][x] >= 0 && lastY === null) {
          lastY = y;
        }
      }
      if (lastY !== null) {
        break;
      }
    }

    /* find first X */
    for (let x = 0; x < data.matrix[0].length - 1; x++) {
      for (let y = 0; y < data.matrix.length; y++) {
        if (data.matrix[y][x] >= 0 && firstX === null) {
          firstX = x;
        }
      }
      if (firstX !== null) {
        break;
      }
    }

    /* find last X */
    for (let x = data.matrix[0].length - 1; x >= 0; x--) {
      for (let y = 0; y < data.matrix.length; y++) {
        if (data.matrix[y][x] >= 0 && lastX === null) {
          lastX = x;
        }
      }
      if (lastX !== null) {
        break;
      }
    }

    /* data.matrix.forEach((row, y) => {
      row.forEach((cell, x) => {
        if (cell >= 0) {
          if (firstX === null) {
            firstX = x;
          }
          if (firstY === null) {
            firstY = y;
          }
        }
      });
    }); */

    /* console.log(firstX, firstY, lastX, lastY, data.matrix.length); */
  }

  const departmentsWithCubes = [...data.departments]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(department => {
      const departmentCubes = [...data.cubes].filter(cube =>
        version === 1 ? cube.department === department.id : cube.department === department.name
      ); /*(cube => cube.department === department.id) before in cube field department was id, not name */
      const departmentCubesSorted =
        departmentCubes.length > 0
          ? departmentCubes.sort((a, b) => a.name.localeCompare(b.name))
          : [];
      return {
        ...department,
        children: departmentCubesSorted
      };
    });

  let totalCubeArea = 0;
  const cubesExcel = [];
  const cubesWithDepName = [...data.cubes]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(c => {
      const depName =
        version === 1
          ? data.departments.find(d => d.id === c.department)?.name
          : ''; /*find(d => d.id === c.department)?.name;  */
      const cubeArea = Math.ceil(c['x-dim'] * c['y-dim'] * 100) / 100;
      totalCubeArea = totalCubeArea + cubeArea;

      cubesExcel.push({
        Name: c.name,
        'Functional Unit': c.facility_type,
        Division: version === 1 ? depName : c.department,
        'X-Position [m]': c['x-pos'],
        'Y-Position [m]': c['y-pos'],
        'Z-Position [m]': c['z-pos'],
        'Length [m]': c['x-dim'],
        'Width [m]': c['y-dim'],
        'Height [m]': c['z-dim'],
        'Area [m²]': cubeArea,
        'Colour [HEX]': facilityColors[c.facility_type]
      });
      return { ...c, depName: version === 1 ? depName : c.department };
    });

  let maxIntensity = null;
  let minIntensity = null;

  /*   const connectionsWithCubeDim = data.connections.map(flow => {
    const cubeFrom = data.cubes.find(cube => cube.id === flow.from);
    const toArray = flow.to.map(ct => {
      const cubeTo = data.cubes.find(cube => cube.id === ct.id);
      maxIntensity = maxIntensity === null ? ct.value : Math.max(maxIntensity, ct.value);
      minIntensity = minIntensity === null ? ct.value : Math.min(minIntensity, ct.value);
      return {
        ...ct,
        pos: {
          posX: cubeTo.sink['x-pos'],
          posY: cubeTo.sink['y-pos'],
          posZ: 0.1
        }
      };
    });
    return {
      id: flow.from.concat('from'),
      name: flow.name,
      from: flow.from,
      children: toArray,
      pos: {
        posX: cubeFrom.source['x-pos'],
        posY: cubeFrom.source['y-pos'],
        posZ: 0.1
      }
    };
  });
 */

  /* const excelFlowArray = [
    ['DEPENDENCIES', 'to', 'Block1 Long name test', '', 'Block2', '',],
    ['from', '', 'Intensity', 'Distance [m]', 'Intensity', 'Distance [m]'],
    ['Block1', '', '', '', 5, 5],
    ['Block2', '', 10, 10, '', '']
  ]; */

  const siteArea = Math.ceil(data.project['y-dim'] * data.project['x-dim'] * 100) / 100;
  const flowNum = version === 2 ? data.numConnections : '';
  const projectName = version === 2 ? data.project.name : '';

  const excelProject = [
    ['PROJECT'],
    ['Name', projectName],
    /*  ['Description', ''], */
    ['Site Size [m]', 'Length [m]', 'Width [m]' /* , 'max. Height [m]' */],
    ['', data.project['y-dim'], data.project['x-dim'], ''],
    ['', ''],
    ['Site Area [m²]', siteArea],
    ['Layout Block Area [m²]', totalCubeArea],
    /* ['Area of pathways [m²]', ''], */
    ['', ''],
    ['Nr. of Layout Blocks', data.cubes.length],
    ['Nr. of Dependencies', flowNum],
    /* ['Nr. of Employees', ''], */
    ['', ''],
    ['KPIs'],
    ['Material flow length [m]', data.kpi?.mf_distance_meters],
    ['Layout area [m²]', data.kpi?.area_utilization],
    ['Land use degree [%]', (data.kpi?.land_use_degree * 100).toFixed(2)]
  ];

  const excelFlowArray = [
    ['', 'to'],
    ['from', '']
  ];

  const cubeNameList = [];
  const cubeNameObj = {};

  const connFlowSorted = data.connFlow
    ? data.connFlow.sort((a, b) => a.name.localeCompare(b.name))
    : [];

  connFlowSorted.forEach(flow => {
    cubeNameList.push(flow.name);
    cubeNameList.push('');
    excelFlowArray[0].push(flow.name, '');
    excelFlowArray[1].push('Intensity', 'Distance [m]');
    cubeNameObj[flow.name] = ['', ''];
  });

  const connFlowWithPos = connFlowSorted.map(flow => {
    const flowOutObj = { ...cubeNameObj };
    const flowOutArray = [flow.name, ''];
    const cubeParent = data.cubes.find(cube => cube.id === flow.id);

    const inArray = flow.in
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(cin => {
        const cubeTo = data.cubes.find(cube => cube.id === cin.id);
        maxIntensity =
          maxIntensity === null ? cin.intensity : Math.max(maxIntensity, cin.intensity);
        minIntensity =
          minIntensity === null ? cin.intensity : Math.min(minIntensity, cin.intensity);
        return {
          ...cin,
          pos: {
            posX: cubeTo.sink['x-pos'],
            posY: cubeTo.sink['y-pos'],
            posZ: 0.1
          },
          type: 'in'
        };
      });

    const outArray = flow.out
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(cout => {
        flowOutObj[cout.name] = [cout.intensity, cout.length];
        const cubeOut = data.cubes.find(cube => cube.id === cout.id);
        maxIntensity =
          maxIntensity === null ? cout.intensity : Math.max(maxIntensity, cout.intensity);
        minIntensity =
          minIntensity === null ? cout.intensity : Math.min(minIntensity, cout.intensity);
        return {
          ...cout,
          pos: {
            posX: cubeOut.source['x-pos'],
            posY: cubeOut.source['y-pos'],
            posZ: 0.1
          },
          type: 'out'
        };
      });

    flowOutArray.push(...Object.values(flowOutObj).flatMap(v => v));
    excelFlowArray.push(flowOutArray);

    return {
      id: flow.id.concat('parent'),
      name: flow.name,
      out: outArray,
      children: [...inArray, ...outArray],
      pos: {
        posX: cubeParent.source['x-pos'],
        posY: cubeParent.source['y-pos'],
        posZ: 0.1
      }
    };
  });

  /* console.log(excelFlowArray, 'excelFlowArray'); */

  let ftTotalArea = 0;
  data.facilityTypes?.forEach(
    ft => {
      ftTotalArea = ftTotalArea + ft.area;
    } /* (ftTotalArea = ftTotalArea + ft.area) */
  );

  const facility = data.facilityTypes
    ? data.facilityTypes
        .sort((a, b) => b.area - a.area)
        .map((f, i) => ({
          ...f,
          id: f.name,
          value: ((f.area / ftTotalArea) * 100).toFixed(),
          label: f.name,
          percentage: ((f.area / ftTotalArea) * 100).toFixed(),
          color: facilityColors[f.name]
        }))
    : [];

  const excel = {
    project: excelProject,
    layout: cubesExcel,
    flow: excelFlowArray,
    index: index
  };

  const kpis = [
    { name: 'Material flow length', value: data.kpi?.mf_distance_meters, id: 'kpi-1', unit: 'm' },
    { name: 'Layout area', value: data.kpi?.area_utilization, id: 'kpi-2', unit: 'm²' },
    {
      name: 'Land use degree',
      value: (data.kpi?.land_use_degree * 100).toFixed(2),
      id: 'kpi-3',
      unit: '%'
    }
  ];

  return {
    layout: {
      cubes: cubesWithDepName,
      project: data.project,
      flows: connFlowWithPos,
      flowIntensity: { max: maxIntensity, min: minIntensity },
      path: data.paths,
      extremePoints: version === 2 ? { firstX, lastX, firstY, lastY } : null
    },
    cubes: departmentsWithCubes,
    flows: connFlowWithPos,
    facility,
    excel,
    kpis
  };
}
