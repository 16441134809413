import { useState } from 'react';
import { ReactComponent as IconChevronRight } from '../../assets/chevron-right.svg';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import TreeCheckbox from './select-tree/tree-checkbox';
import { useCubesState } from '../../context/sidebar-selection';

import '../model.css';

export const CubeSelection = ({ cubesList }) => {
  const [selectedCubes, setSelectedCubes] = useCubesState();

  /*   const [expanded, setExpanded] = useState(false);
  const handleChange = () => {
    setExpanded(prev => !prev);
  }; */

  return (
    <div className='sidebar-cube-selection'>
      <TreeCheckbox
        parentsList={cubesList}
        isFlow={false}
        selectedChildren={selectedCubes}
        setSelectedChildren={setSelectedCubes}
      />
      {/* <Accordion
        expanded={expanded}
        onChange={handleChange}
        disableGutters
        elevation={0}
        square
        className='accordion'
      >
        <AccordionSummary
          expandIcon={<IconChevronRight width='18px' height='18px' />}
          aria-controls='panel1d-content'
          id='panel1d-header'
          className='accordion-summary'
        >
          <Typography>Functional Units & Layout Blocks</Typography>
        </AccordionSummary>
        <AccordionDetails className='accordion-details'>
          <TreeCheckbox parentsList={departmentsList} />
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
};
