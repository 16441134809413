import { useState } from 'react';

import './model.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { CubeSelection } from './sidebar/cube-selection';
import { Balance } from './sidebar/balance';
import TreeCheckbox from './sidebar/select-tree/tree-checkbox';
import { themeOptions } from '../mui-theme';
import { ReactComponent as IconChevronRight } from '../assets/chevron-right.svg';
import { FlowSelection } from './sidebar/flow-selection';
import { luckyorangeTrackEven } from '../utils/luckyorange-events';

export const ThreeDSidebarTabs = ({ cubesList, flowsList, facility, kpis }) => {
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setExpanded(true);
    }
    setTabValue(newValue);
    const tabName = tabValue === 0 ? 'tab1-cubes' : tabValue === 1 ? 'tab2-kpis' : 'tab3-flow';
    luckyorangeTrackEven(`view-${tabName}`);
  };

  const handleExpandChange = () => {
    setExpanded(prev => !prev);
  };

  const facilityColors = {
    Manufacturing: '#5E6278', // green
    Assembly: '#9F9694', // orange
    Logistics: '#54504C', // yellow
    'Social areas (break room, toilets)': '#EFA21E', // blue
    'Office, meeting room': '#E6D97A', // brown
    'Conversion area': '#7A2E0E', // black
    'TGA area': '#3E2723', // grey
    'Barrier area': '#AA1E1E', // red
    'Other area': '#FEF7DD' // white
  };

  return (
    <div
      className='sidebar-tabs'
      style={{
        paddingBottom: expanded ? '70px' : '8px',
        transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
      }}
    >
      <div className={`tab-content ${expanded ? '' : 'full-height'}`}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label='basic tabs example'
          variant='scrollable'
          scrollButtons='auto'
          TabIndicatorProps={{
            sx: {
              height: '2px'
              //borderRadius: '3px'
            }
          }}
          sx={{
            minHeight: 0,
            mb: '20px',
            '& .MuiTabs-scrollButtons': { width: '20px' }
          }}
        >
          <Tab
            label='Layout Blocks'
            sx={{
              color: 'text.primary',
              textTransform: 'inherit',
              minHeight: 0,
              minWidth: 0,
              padding: '8px 12px',
              fontWeight: 500,
              fontSize: '1rem'
            }}
            /* wrapped */
          />
          <Tab
            label='KPIs'
            sx={{
              color: 'text.primary',
              textTransform: 'inherit',
              minHeight: 0,
              minWidth: 0,
              padding: '8px 12px',
              fontWeight: 500,
              fontSize: '1rem'
            }}
          />
          <Tab
            label='Block Dependencies'
            sx={{
              color: 'text.primary',
              textTransform: 'inherit',
              minHeight: 0,
              minWidth: 0,
              padding: '8px 12px',
              fontWeight: 500,
              fontSize: '1rem'
            }}
          />
        </Tabs>
        {tabValue === 0 ? (
          <CubeSelection cubesList={cubesList} />
        ) : tabValue === 1 ? (
          <Balance facility={facility} kpis={kpis} />
        ) : (
          <FlowSelection flowsList={flowsList} />
        )}
      </div>

      <div className='facility-box'>
        <Accordion
          expanded={expanded}
          onChange={handleExpandChange}
          disableGutters
          elevation={0}
          square
          className='accordion'
        >
          <AccordionSummary
            expandIcon={<IconChevronRight width='18px' height='18px' />}
            aria-controls='panel1d-content'
            id='panel1d-header'
            className='accordion-summary'
          >
            <Box className='legend-header'>
              <Typography>Functional Units</Typography>
              {expanded && (
                <Box className='value'>
                  <Typography width='100px' mr='12px'>
                    Area <span className='unit'>[m²]</span>
                  </Typography>
                  <Typography width='80px'>
                    Share <span className='unit'>[%]</span>
                  </Typography>
                </Box>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails className='accordion-details'>
            <div className='table-legend'>
              {/*  <Box className='legend-header'>
                <Typography>Facility Type</Typography>
                <Box className='value'>
                  <Typography width='100px' mr='12px'>
                    Area <span className='unit'>[m²]</span>
                  </Typography>
                  <Typography width='80px'>Share</Typography>
                </Box>
              </Box> */}
              {facility.length > 0 ? (
                facility.map(ft => (
                  <Box className='legend-row' key={ft.id}>
                    <Box className='title'>
                      <div className='color' style={{ background: ft.color }} />
                      <Typography>{ft.label}</Typography>
                    </Box>

                    <Box className='value'>
                      <Typography width='100px' mr='12px'>
                        {ft.area}
                      </Typography>
                      <Typography width='80px'>{ft.percentage}</Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <div>Facility types are not defined</div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};
