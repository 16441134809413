import { useEffect, useState } from 'react';
import { api } from '../api/api';
import axios from 'axios';
import { convertCubes } from '../utils/convert-cubes';
import { useParams } from 'react-router-dom';
import { ThreeDImage } from '../components/3d-image';
import { useRollbar } from '@rollbar/react';
import { ErrorMessage } from '../global/error/error';

import '../components/page-wrapper.css';

export const ImagePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const rollbar = useRollbar();

  const [layoutData, setLayoutData] = useState(null);

  const { cubesVersion, runnerId, index } = useParams();

  const fetchData = async controller => {
    setIsLoading(true);
    setError(false);
    setLayoutData(null);

    try {
      const response = await api.get(
        `results/${cubesVersion}/${runnerId}/${index}`
        /* {
            headers: { Authorization: `Token ${token}` },
            signal: controller ? controller.signal : null
          } */
      );

      /* console.log(response); */
      const { layout, cubes, flows, facility, excel, kpis } = convertCubes(response.data);
      setLayoutData({ threeDCubes: layout, cubes, flows, facility, excel, kpis });
      setIsLoading(false);
      setError(false);
    } catch (error) {
      rollbar.error('Error fetching data', error);
      if (axios.isCancel(error)) {
        console.log('axios cancel');
      } else {
        setError(true);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller);

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className='page-wrapper'>
      {isLoading && <div id='status'>Loading</div>}
      {!isLoading && error && <ErrorMessage />}
      {!isLoading && !error && layoutData && (
        <ThreeDImage layoutData={layoutData.threeDCubes} isThreeD={true} />
      )}
    </div>
  );
};
