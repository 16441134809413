import axios from 'axios';

/* export const API_HOST = 'https://fm-vm-backend2.francecentral.cloudapp.azure.com/'; */
export const API_HOST = 'https://layout-backend.factorymaker.at/';
export const API_HOST1 = 'https://425c-185-142-131-135.ngrok-free.app/';

export const api = axios.create({
  baseURL: API_HOST
});

/* api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}); */
