import { useBounds } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import gsap from 'gsap';
import { useEffect } from 'react';
import * as THREE from 'three';

export const ZoomWrapper = ({ children, reset, isThreeD, zoomIn, zoomOut }) => {
  /* const bounds = useBounds(); */
  /* useEffect(() => {
    bounds.refresh().fit();
    console.log('reset trigger');
  }, [reset]); */

  const { camera, controls } = useThree();

  useEffect(() => {
    /*  debugger; */
    /*  console.log(camera, 'camera'); */

    if (!isThreeD && camera) {
      /* camera.position.set(0, -3, 40); // default 0, 0, 50
      camera.lookAt(0, -3, -0);
      camera.updateProjectionMatrix(); */
      /* camera?.rotation.set(THREE.MathUtils.degToRad(30), 0, 0); */
      const position = camera.position; /* 
      const rotation = camera.rotation;
      console.log(camera, position, rotation,'position'); */
      /* camera.lookAt(new THREE.Vector3(0, 0, 0)); */
      /* bounds.to([0, position.y, 0]); */
      controls.reset();
      camera.rotation.set(0, 0, 0);
      /* bounds.reset();
      camera.rotation.set(0, 0, 0); */
      camera.position.set(0, position.y, 0);
      /* camera.rotation.set(0, 0, 0); */

      /*  gsap.to(camera.position, {
        duration: 1,
        x: 0,
        y: position.y,
        z: 0,
        ease: 'power3.out'
      }); */

      camera.updateProjectionMatrix();
      /* camera.lookAt(0, position.y, 0);
      camera.updateProjectionMatrix(); */
      /* bounds.moveTo([0, position.y, 0]); */
    }
  }, [isThreeD]);

  useEffect(() => {
    if (camera) {
      /* camera.position.set(position.x * 0.8, position.y * 0.8, position.z * 0.8); */
      //camera.updateProjectionMatrix();
      const position = camera.position;
      /* console.log(camera, position, 'position'); */
      if (position.y > 5) {
        const newX = position.x * 0.8;
        const newY = position.y * 0.8;
        const newZ = position.z * 0.8;

        gsap.to(camera.position, {
          duration: 1,
          x: newX,
          y: newY,
          z: newZ,
          ease: 'power3.out'
        });
      }
    }
  }, [zoomIn]);

  useEffect(() => {
    if (camera) {
      /* camera.position.set(position.x * 0.8, position.y * 0.8, position.z * 0.8); */
      //camera.updateProjectionMatrix();
      const position = camera.position;
      /* console.log(position, 'position'); */
      if (position.y > 2) {
        const newX = position.x * 1.2;
        const newY = position.y * 1.2;
        const newZ = position.z * 1.2;

        gsap.to(camera.position, {
          duration: 1,
          x: newX,
          y: newY,
          z: newZ,
          ease: 'power3.out'
        });
      }
    }
  }, [zoomOut]);

  return <group>{children}</group>;
};
