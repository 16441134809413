import FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const jsonData = [
  {
    Name: 'Fam',
    'Functional Unit': 'Jhon',
    Division: 'MA',
    'X-Position [m]': 10,
    'Y-Position [m]': 10,
    'Z-Position [m]': 10,
    'Length [m]': 10,
    'Width [m]': 10,
    'Height [m]': 10,
    'Area [m2]': 10,
    'Colour [HEX]': 10,
    'External wall positioning': 'no',
    'Nr. of Employees': 10,
    'Media System(s)': 'Water'
  }
];
const aoa = [
  ['DEPENDENCIES', 'to', 'Block1 Long name test', , 'Block2', ,],
  ['from', , 'Intensity', 'Distance [m]', 'Intensity', 'Distance [m]'],
  ['Block1', '', '', '', 5, 5],
  ['Block2', '', 10, 10, '', '']
];

export const exportExcel = (excelData, index) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  //create excel sheets
  const sheetProject = XLSX.utils.aoa_to_sheet(excelData.project);
  const sheetLayout = XLSX.utils.json_to_sheet(excelData.layout);
  const sheetDep = XLSX.utils.aoa_to_sheet(excelData.flow);

  //style header in PROJECT sheet
  /*  const projectBoldCellNum = [
    'A2',
    'A3',
    'A4',
    'A7',
    'A8',
    'A9',
    'A11',
    'A12',
    'A13',
    'A16',
    'A17',
    'A18',
    'B4',
    'C4',
    'D4'
  ]; */

  const projectBoldCellNum = [
    'A2',
    'A3',
    'A6',
    'A7',
    'A9',
    'A10',
    'A13',
    'A14',
    'A15',
    'B3',
    'C3'
    /* 'D3' */
  ];

  sheetProject['A1'].s = {
    font: {
      bold: true,
      color: { rgb: 'efa21e' }
    }
  };
  sheetProject['A12'].s = {
    font: {
      bold: true,
      color: { rgb: 'efa21e' }
    }
  };

  projectBoldCellNum.forEach(cell => {
    console.log(cell, typeof cell, 'cell');
    sheetProject[cell].s = {
      font: {
        bold: true
      }
    };
  });

  //style header in LAYOUT sheet
  const layoutBoldCellNum = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1'];
  layoutBoldCellNum.forEach(cell => {
    sheetLayout[cell].s = {
      font: {
        bold: true
      }
    };
  });

  //style header in DEPENDENCIES sheet
  sheetDep['A1'].s = {
    font: {
      bold: true,
      color: { rgb: 'efa21e' }
    }
  };
  sheetDep['A2'].s = {
    font: {
      bold: true,
      color: { rgb: 'efa21e' }
    }
  };
  sheetDep['B1'].s = {
    font: {
      bold: true,
      color: { rgb: 'efa21e' }
    }
  };

  const layout = Number(excelData.index) + 1;

  //create excel book
  const workbook = XLSX.utils.book_new();

  //add sheets
  XLSX.utils.book_append_sheet(workbook, sheetProject, 'PROJECT_INFO');
  XLSX.utils.book_append_sheet(workbook, sheetLayout, `LAYOUT ${layout}`);
  XLSX.utils.book_append_sheet(workbook, sheetDep, 'DEPENDENCIES');

  XLSX.writeFile(workbook, `Layout_${layout}.xlsx`);

  /*   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([workbook], { type: 'application/octet-stream' });

  const blob2 = new Blob([excelBuffer], {
    type: fileType
  });

  console.log(blob, blob2, excelBuffer, 'blob file'); */

  /* FileSaver.saveAs(blob, 'data.xlsx');  */
  /*  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, '.xlsx' + fileType); */
};
