import { createContext, useState, useContext } from 'react';

const CubesContext = createContext(null);
const FlowsContext = createContext(null);

export function useCubesState() {
  return useContext(CubesContext);
}
export function useFlowsState() {
  return useContext(FlowsContext);
}

function SidebarSelectionState({ cubes, flows, children }) {
  const [selectedCubes, setSelectedCubes] = useState([...cubes]);
  const [selectedFlows, setSelectedFlows] = useState([...flows]);

  return (
    <CubesContext.Provider value={[selectedCubes, setSelectedCubes]}>
      <FlowsContext.Provider value={[selectedFlows, setSelectedFlows]}>
        {children}
      </FlowsContext.Provider>
    </CubesContext.Provider>
  );
}

export default SidebarSelectionState;
