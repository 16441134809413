import { DoubleSide } from 'three';
import { Text } from '@react-three/drei';
import { CustomLine } from './line';
import { calculateTextSize } from '../../utils/calculate-size';

export const DimensionLine = ({ property, extremePoints, gridSize }) => {
  /* const [ref] = usePlane(() => ({ mass: 0, ...props }), useRef()); */
  const lengthX = extremePoints.lastX - extremePoints.firstX + 1;
  const lengthY = extremePoints.lastY - extremePoints.firstY + 1;

  /* console.log(
    lengthX,
    lengthY,
    'lengthX, lengthY',
    property[0] / 2 - extremePoints.firstX - lengthX / 2
  ); */

  const newX = 0 - (property[0] / 2 - extremePoints.firstX - lengthX / 2);
  const newY = 0 - (property[1] / 2 - extremePoints.firstY - lengthY / 2);

  const textSize = calculateTextSize(gridSize);
  return (
    <>
      {/* x-dim line */}
      {/* <CustomLine
        points={[
          [-property[0] / 2 + extremePoints.firstX, 0, -property[1] / 2 - 2],
          [+property[0] / 2 - (property[0] - 1 - extremePoints.lastX), 0, -property[1] / 2 - 2]
        ]}
      /> */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[newX, 0.1, -property[1] / 2 - 2]}
        receiveShadow
      >
        <planeGeometry args={[lengthX, 0.1]} />
        <meshBasicMaterial color='#000' side={DoubleSide} depthWrite={false} flatShading={false} />
      </mesh>
      {/* x left line */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[-property[0] / 2 + extremePoints.firstX, 0.1, -property[1] / 2 - 2]}
        receiveShadow
      >
        <planeGeometry args={[0.1, 4]} />
        <meshBasicMaterial color='#000' side={DoubleSide} depthWrite={false} flatShading={false} />
      </mesh>
      {/* x right line */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[
          +property[0] / 2 - (property[0] - 1 - extremePoints.lastX),
          0.1,
          -property[1] / 2 - 2
        ]}
        receiveShadow
      >
        <planeGeometry args={[0.1, 4]} />
        <meshBasicMaterial color='#000' side={DoubleSide} depthWrite={false} flatShading={false} />
      </mesh>
      <Text
        position={[newX, 0, -property[1] / 2 - 3]}
        rotation={[-Math.PI / 2, 0, 0]}
        text={`- ${lengthX} m -`}
        fontSize={textSize * 1.5}
        color={'#000'}
        fontWeight={600}
        depthWrite={false}
        flatShading={false}
      />
      {/* y-dim line */}
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[-property[0] / 2 - 2, 0.1, newY]}>
        <planeGeometry args={[0.1, lengthY]} />
        <meshBasicMaterial color='#000' side={DoubleSide} depthWrite={false} flatShading={false} />
      </mesh>
      {/* y top line */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[-property[0] / 2 - 2, 0.1, -property[1] / 2 + extremePoints.firstY]}
      >
        <planeGeometry args={[4, 0.1]} />
        <meshBasicMaterial color='#000' side={DoubleSide} depthWrite={false} flatShading={false} />
      </mesh>
      {/* y bottom line */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[
          -property[0] / 2 - 2,
          0.1,
          +property[1] / 2 - (property[1] - 1 - extremePoints.lastY)
        ]}
      >
        <planeGeometry args={[4, 0.1]} />
        <meshBasicMaterial color='#000' side={DoubleSide} depthWrite={false} flatShading={false} />
      </mesh>
      <Text
        position={[-property[0] / 2 - 3, 0.1, newY]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        text={`- ${lengthY} m -`}
        fontSize={textSize * 1.5}
        color={'#000'}
        fontWeight={600}
        depthWrite={false}
        flatShading={false}
      />
    </>
  );
};
