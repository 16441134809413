import { Canvas } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import {
  Bounds,
  GizmoHelper,
  GizmoViewcube,
  GizmoViewport,
  OrbitControls,
  PerspectiveCamera
} from '@react-three/drei';
import { useCubesState, useFlowsState } from '../context/sidebar-selection';
import { GLTFExporter, OBJExporter } from 'three/examples/jsm/Addons.js';
import { facilityBorder, facilityColors } from '../utils/convert-cubes';
import { ZoomWrapper } from './model/zoom-wrapper';
import { Cube } from './model/cube';
import { CubeTwoD } from './model/cube-2d';
import { FlowLine } from './model/flow';
import { Floor } from './model/floor';
import { Path } from './model/path';
import { DimensionLine } from './model/dimension-line';
import useScreenSize from '../utils/useScreenSize';

export const ThreeDModel = ({
  layoutData,
  reset,
  isThreeD,
  showGrid,
  showFlow,
  downloadImage,
  downloadOBJ,
  cubeClickHandler,
  cubeDetails,
  zoomIn,
  zoomOut
}) => {
  const [selectedCubes, setSelectedCubes] = useCubesState();
  const [selectedFlows, setSelectedFlows] = useFlowsState();

  const orbitRef = useRef();
  const canvasRef = useRef();
  const downloadGroupRef = useRef();

  /* const screenSize = useScreenSize();
  console.log(screenSize, 'screenSize'); */

  useEffect(() => {
    orbitRef?.current?.reset();
  }, [reset]);

  const gridSize = Math.max(layoutData.project['x-dim'], layoutData.project['y-dim']);

  // prinScreen
  const downloadScreenshot = () => {
    const image = canvasRef.current
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    console.log(image);
    const a = document.createElement('a');
    a.setAttribute('download', 'factorymaker-screenshot.png');
    a.setAttribute('href', image);
    a.click();
  };

  useEffect(() => {
    if (downloadImage) {
      downloadScreenshot();
    }
  }, [downloadImage]);

  //download obj

  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);

  function save(blob, filename) {
    link.href = URL.createObjectURL(blob);
    console.log(link, blob);
    link.download = filename;
    link.click();
  }

  function saveString(text, filename) {
    save(new Blob([text], { type: 'text/plain' }), filename);
  }

  const handleExport = () => {
    const exporter = /* new GLTFExporter();  */ new OBJExporter();
    const result = exporter.parse(downloadGroupRef.current);
    saveString(result, 'object.obj');
    /*         const link = document.createElement('a');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.href = URL.createObjectURL(new Blob([result], { type: 'text/plain' }));
        link.download = 'object.obj';
        link.click(); */
  };

  //download gltf

  useEffect(() => {
    if (downloadOBJ) {
      /* handleExport(); */
      exportGLTF();
    }
  }, [downloadOBJ]);

  function exportGLTF(input) {
    const gltfExporter = new GLTFExporter();

    const options = {
      binary: false
    };

    gltfExporter.parse(
      downloadGroupRef.current,
      function (result) {
        if (result instanceof ArrayBuffer) {
          saveArrayBuffer(result, 'scene.glb');
        } else {
          const output = JSON.stringify(result, null, 2);
          console.log(output);
          saveStringGLTF(output, 'factorymaker-3D.gltf');
        }
      },
      function (error) {
        console.log('An error happened during parsing', error);
      },
      options
    );
  }

  const linkGLTF = document.createElement('a');
  linkGLTF.style.display = 'none';
  document.body.appendChild(linkGLTF); // Firefox workaround

  function saveGLTF(blob, filename) {
    linkGLTF.href = URL.createObjectURL(blob);
    linkGLTF.download = filename;
    linkGLTF.click();

    // URL.revokeObjectURL( url ); breaks Firefox...
  }

  function saveStringGLTF(text, filename) {
    saveGLTF(new Blob([text], { type: 'text/plain' }), filename);
  }

  function saveArrayBuffer(buffer, filename) {
    saveGLTF(new Blob([buffer], { type: 'application/octet-stream' }), filename);
  }

  const cameraZ = gridSize + 10;
  return (
    <Canvas
      style={{
        width: '100%',
        height: '100%',
        // background: '#333333',
        background: '#fff'
      }}
      ref={canvasRef}
      gl={{ preserveDrawingBuffer: true }}
      /* camera={[0, 100, 80]} */
    >
      {/* <color attach='background' args={['#f8f7f6']} /> */}
      {/* <ambientLight intensity={0.5} /> */}
      {/* <directionalLight intensity={0.5} position={[10, 10, 5]} /> position={[0, 0, 2]} */}
      {/*  <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
      <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} /> */}
      {/* <CameraControls ref={cameraRef} makeDefault /> */}
      <ambientLight intensity={0.5} />
      <PerspectiveCamera makeDefault position={[0, cameraZ, cameraZ]} />
      <OrbitControls
        ref={orbitRef}
        makeDefault
        listenToKeyEvents={true}
        keys={{ LEFT: 'KeyA', UP: 'KeyW', RIGHT: 'KeyD', BOTTOM: 'KeyS' }}
        maxPolarAngle={Math.PI / 2}
      />
      {/* <Bounds fit clip observe margin={1.2} maxDuration={0} fixedOrientation> */}
      <ZoomWrapper reset={reset} isThreeD={isThreeD} zoomIn={zoomIn} zoomOut={zoomOut}>
        <group ref={downloadGroupRef}>
          <Floor property={[layoutData.project['x-dim'], layoutData.project['y-dim']]} />
          {showGrid && layoutData.extremePoints !== null && (
            <DimensionLine
              property={[layoutData.project['x-dim'], layoutData.project['y-dim']]}
              extremePoints={layoutData.extremePoints}
              gridSize={gridSize}
            />
          )}
          <group
            position={[
              -layoutData.project['x-dim'] / 2,
              0,
              -layoutData.project['y-dim'] / 2
            ]} /*rotation={[-Math.PI / 2, 0, 0]} */
          >
            {/* {cubeDetails && (
                <arrowHelper
                  args={[
                    new THREE.Vector3([
                      cubeDetails['x-pos'],
                      cubeDetails['z-dim'],
                      cubeDetails['y-pos']
                    ]).normalize(),
                    new THREE.Vector3([cubeDetails['x-pos'], 0, cubeDetails['y-pos']]),
                    1,
                    0xffff00
                  ]}
                />
              )} */}
            {isThreeD
              ? layoutData.cubes.map((cube, index) => (
                  <Cube
                    key={cube.id}
                    position={[
                      cube['x-pos'] + cube['x-dim'] / 2,
                      isThreeD ? cube['z-dim'] / 2 : 0.05,
                      cube['y-pos'] + cube['y-dim'] / 2
                    ]}
                    dimensions={[cube['x-dim'], isThreeD ? cube['z-dim'] : 0, cube['y-dim']]}
                    height={isThreeD ? cube['z-dim'] : 0.1}
                    color={cube.facility_type ? facilityColors[cube.facility_type] : '#a3aed0'}
                    border={cube.facility_type ? facilityBorder[cube.facility_type] : '#a3aed0'}
                    opacity={
                      selectedCubes.includes(cube.id)
                        ? cubeDetails?.id === cube.id
                          ? 0.5
                          : 0.9
                        : 0.2
                    }
                    isThreeD={isThreeD}
                    cubeClickHandler={cubeClickHandler}
                    cube={cube}
                    cubeSelected={cubeDetails?.id === cube.id}
                    floor={layoutData.project}
                    maxLayoutSize={gridSize}
                    /* rotation={[0, (-Math.PI / 2) * cube.curr_rotation, 0]} */
                    /* position={
              cube.curr_rotation % 2 === 0
                ? [
                    cube.curr_position[1] + cube.width1 / 2,
                    isThreeD ? cube.height / 2 : 0,
                    cube.curr_position[0] + cube.width2 / 2
                  ]
                : [
                    cube.curr_position[1] + cube.width2 / 2,
                    isThreeD ? cube.height / 2 : 0,
                    cube.curr_position[0] + cube.width1 / 2
                  ]
            } */
                    /*  dimensions={
              cube.curr_rotation % 2 === 0
                ? [cube.width1, isThreeD ? cube.height : 0, cube.width2]
                : [cube.width2, isThreeD ? cube.height : 0, cube.width1]
            } */
                  />
                ))
              : layoutData.cubes.map((cube, index) => (
                  <CubeTwoD
                    key={cube.id}
                    position={[
                      cube['x-pos'] + cube['x-dim'] / 2,
                      0.05,
                      cube['y-pos'] + cube['y-dim'] / 2
                    ]}
                    dimensions={[cube['x-dim'], 0, cube['y-dim']]}
                    height={0.1}
                    color={cube.facility_type ? facilityColors[cube.facility_type] : '#a3aed0'}
                    opacity={
                      selectedCubes.includes(cube.id)
                        ? cubeDetails?.id === cube.id
                          ? 0.5
                          : 0.9
                        : 0.2
                    }
                    isThreeD={isThreeD}
                    cubeClickHandler={cubeClickHandler}
                    cube={cube}
                    cubeSelected={cubeDetails?.id === cube.id}
                    floor={layoutData.project}
                    maxLayoutSize={gridSize}
                  />
                ))}
          </group>

          <group position={[-layoutData.project['x-dim'] / 2, 0, -layoutData.project['y-dim'] / 2]}>
            {layoutData.path &&
              layoutData.path.map((itemPath, index) =>
                itemPath.map(cell => (
                  <Path
                    key={`path-cell${cell[0]}${cell[1]}`}
                    position={[
                      cell[1] + layoutData.project.cell_size / 2,
                      0,
                      cell[0] + layoutData.project.cell_size / 2
                    ]}
                    cellSize={layoutData.project.cell_size}
                  />
                ))
              )}
          </group>
          {/*  <Arrow />

            <CustomShape /> */}
          {showFlow && (
            <group
              position={[-layoutData.project['x-dim'] / 2, 0, -layoutData.project['y-dim'] / 2]}
            >
              {layoutData.flows.map((flowFrom, index) =>
                flowFrom.out.map(flowTo => (
                  <FlowLine
                    key={flowTo.id}
                    start={[flowFrom.pos.posX, flowFrom.pos.posZ, flowFrom.pos.posY]}
                    end={[flowTo.pos.posX, flowTo.pos.posZ, flowTo.pos.posY]}
                    selected={selectedFlows.includes(flowTo.connection_id)}
                    flowIntensity={layoutData.flowIntensity}
                    intensityValue={flowTo.intensity}
                    maxLayoutSize={gridSize}
                  />
                ))
              )}
            </group>
          )}
        </group>
      </ZoomWrapper>{' '}
      {/* </Bounds> */}
      {/* <CustomShape /> */}
      {/* <CustomArrow from={[0, 0, 0]} to={[2, 2, 2]} color={0x649f8d} thickness={0.05} /> */}
      {/* <axesHelper args={[30]} /> */}
      {showGrid && <gridHelper args={[gridSize, gridSize, '#efa21e', '#a3aed0']} />}
      <GizmoHelper
        alignment='top-left'
        margin={[60, 80]} /* onTarget={() => controls.current.target} */
        onTarget={() => orbitRef?.current?.target0}
        onUpdate={() => {
          if (orbitRef.current) {
            console.log(orbitRef.current, 'orbit ref current');
            orbitRef.current.update();
          }
        }}
      >
        <GizmoViewcube
          hoverColor='#f9ce91'
          color='#fcfcfd'
          strokeColor='black'
          opacity={1}
          controls='OrbitControls'
        />
        <GizmoViewport disabled hideNegativeAxes scale={35} position={[-40, -40, -40]} />
      </GizmoHelper>
      {/* <GizmoHelper alignment='bottom-left' margin={[60, 80]}>
        <GizmoViewport scale={35} />
      </GizmoHelper> */}
      {/* <GizmoViewport disabled hideNegativeAxes scale={15} position={[0, 0, 0]} /> */}
    </Canvas>
  );
};
